import { normalize } from 'normalizr'
import { Bien } from '~/store/biens/biens.types'
import { Collaborateur } from '~/store/collaborateurs/collaborateurs.types'
import { Contact } from '~/store/contacts/contacts.types'
import { Decouverte } from '~/store/decouvertes/decouvertes.types'
import { Vente, VentesEntities } from '~/store/ventes/ventes.types'
import { VisitesEntities } from '~/store/visites/visites.types'
import { NormalizedDemandesRenseignements } from '../demandesRenseignements/demandesRenseignements.normalize'
import { demandesRenseignementsSchema } from '../demandesRenseignements/demandesRenseignements.schema'
import { DemandeRenseignements } from '../demandesRenseignements/demandesRenseignements.types'
import {
  NormalizedRendezVous,
  rendezVousSchema,
} from '../rendezVous/rendezVous.schema'
import { RendezVous } from '../rendezVous/rendezVous.types'
import { achatSchema, ventesLieesSchema } from './achats.schema'
import { Achat } from './achats.types'

type NormalizedAchat = {
  achats: Record<string, Achat>
  decouvertes: Record<string, Decouverte>
  visites: VisitesEntities
  ventes: VentesEntities
  biens: Record<string, Bien>
  collaborateurs: Record<string, Collaborateur>
  contacts: Record<string, Contact>
}

export const normalizeAchat = (achat: Achat): NormalizedAchat =>
  normalize(achat, achatSchema).entities as any
export const normalizeAchats = (achats: Achat[]): NormalizedAchat =>
  normalize(achats, [achatSchema]).entities as any

export const normalizeVentesLiees = (ventesLiees: {
  id: string
  offreVentesToDisplay: Vente[]
  contreVisiteVentesToDisplay: Vente[]
  visiteVentesToDisplay: Vente[]
  ventesSelectionneesToDisplay: Vente[]
  ventesIgnoreesToDisplay: Vente[]
  ventesFavoritesToDisplay: Vente[]
  demandesRenseignements: Vente[]
}): {
  achats: Record<string, Achat>
  ventes: VentesEntities
  biens: Record<string, Bien>
} => normalize(ventesLiees, ventesLieesSchema).entities as any

export const normalizeAchatWithRendezVous = (achatWithRendezVousFromApi: {
  achat: Achat
  rendezVous: RendezVous
}): NormalizedAchat & NormalizedRendezVous =>
  normalize(achatWithRendezVousFromApi, {
    achat: achatSchema,
    rendezVous: rendezVousSchema,
  }).entities as any

export const normalizeAchatWithDemandeRenseignements = (achatWithDemandeRenseignementsFromApi: {
  achat: Achat
  demandeRenseignements: DemandeRenseignements
}): NormalizedAchat & NormalizedDemandesRenseignements =>
  normalize(achatWithDemandeRenseignementsFromApi, {
    achat: achatSchema,
    demandeRenseignements: demandesRenseignementsSchema,
  }).entities as any
