import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight, uniq } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Entities } from '~/store/selectors'
import { Secteur } from './secteurs.types'

export type SecteursState = Readonly<{
  entities: Entities<Secteur>
  secteursCollaborateurLocationIds: string[]
  secteursCollaborateurAchatVenteIds: string[]
}>

const initialState: SecteursState = {
  entities: {},
  secteursCollaborateurLocationIds: [],
  secteursCollaborateurAchatVenteIds: [],
}

const secteursSlice = createSlice({
  name: 'secteurs',
  initialState,
  reducers: {
    updateAllSecteurs: (
      state,
      { payload }: PayloadAction<Record<string, Secteur>>,
    ) => {
      state.entities = payload
    },
    addOrUpdateSecteurs: (
      state,
      { payload }: PayloadAction<Record<string, Secteur>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    addOrUpdateSecteursCollaborateurLocation: (
      state,
      { payload }: PayloadAction<string[]>,
    ) => {
      state.secteursCollaborateurLocationIds = uniq([
        ...(state.secteursCollaborateurLocationIds || []),
        ...payload,
      ])
    },
    addOrUpdateSecteursCollaborateurAchatVenteIds: (
      state,
      { payload }: PayloadAction<string[]>,
    ) => {
      state.secteursCollaborateurAchatVenteIds = uniq([
        ...(state.secteursCollaborateurAchatVenteIds || []),
        ...payload,
      ])
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  updateAllSecteurs,
  addOrUpdateSecteurs,
  addOrUpdateSecteursCollaborateurLocation,
  addOrUpdateSecteursCollaborateurAchatVenteIds,
} = secteursSlice.actions
export const { reducer: secteursReducer } = secteursSlice
