import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Entities } from '~/store/selectors'
import { ConditionSuspensive } from './conditionsSuspensives.types'

export type ConditionsSuspensivesState = Readonly<{
  entities: Entities<ConditionSuspensive>
}>

const initialState: ConditionsSuspensivesState = {
  entities: {},
}

const conditionsSuspensivesSlice = createSlice({
  name: 'conditionsSuspensives',
  initialState,
  reducers: {
    addOrUpdateConditionsSuspensives: (
      state,
      { payload }: PayloadAction<Record<string, ConditionSuspensive>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  addOrUpdateConditionsSuspensives,
} = conditionsSuspensivesSlice.actions
export const {
  reducer: conditionsSuspensivesReducer,
} = conditionsSuspensivesSlice
