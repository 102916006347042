import { curry, descend, head, prop, sort } from 'ramda'
import { compact } from 'ramda-adjunct'
import { selectOffreById } from '~/store/offres/offres.selectors'
import { RootState } from '~/store/types'
import {
  OffreProposition,
  OffrePropositionStatut,
} from './offrePropositions.types'

export const selectOffrePropositionById = curry(
  (propositionId: string, state: RootState) =>
    state.offrePropositions.entities[propositionId],
)

export const selectOffrePropositionAccepteeByOffreId = curry(
  (offreId: string, state: RootState) => {
    const offre = selectOffreById(offreId, state)

    if (!offre || !offre.propositions) return null

    const propositions = offre.propositions.map((propositionId) =>
      selectOffrePropositionById(propositionId, state),
    )

    const acceptedProposition = propositions.find(
      (proposition) => proposition?.statut === OffrePropositionStatut.acceptee,
    )

    if (!acceptedProposition) return null

    return acceptedProposition
  },
)

export const selectSortedOffrePropositionsByOffreId = curry(
  (offreId: string, state: RootState): OffreProposition[] => {
    const offre = selectOffreById(offreId, state)

    if (!offre || !offre.propositions) return []

    return sort(
      descend(prop('createdAt')),
      compact(
        offre.propositions.map((propositionId) =>
          selectOffrePropositionById(propositionId, state),
        ),
      ),
    )
  },
)

export const selectLastOffrePropositionByOffreId = curry(
  (offreId: string, state: RootState): OffreProposition | null =>
    head(selectSortedOffrePropositionsByOffreId(offreId, state)) || null,
)
