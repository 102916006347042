import regularWoff2 from '../../assets/fonts/OpenSans-Regular.woff2'
import regularWoff from '../../assets/fonts/OpenSans-Regular.woff'
import italicWoff2 from '../../assets/fonts/OpenSans-Italic.woff2'
import italicWoff from '../../assets/fonts/OpenSans-Italic.woff'
import boldWoff2 from '../../assets/fonts/OpenSans-Bold.woff2'
import boldWoff from '../../assets/fonts/OpenSans-Bold.woff'
import semiboldWoff2 from '../../assets/fonts/OpenSans-SemiBold.woff2'
import semiboldWoff from '../../assets/fonts/OpenSans-SemiBold.woff'

export const fontName = 'Open Sans' as const

export const fonts = {
  name: fontName,
  definitions: [
    {
      pathWoff2: regularWoff2,
      pathWoff: regularWoff,
    },
    {
      pathWoff2: italicWoff2,
      pathWoff: italicWoff,
      style: 'italic',
    },
    {
      pathWoff2: semiboldWoff2,
      pathWoff: semiboldWoff,
      weight: 600,
    },
    {
      pathWoff2: boldWoff2,
      pathWoff: boldWoff,
      weight: 700,
    },
  ],
}
