import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Entities } from '~/store/selectors'
import { CollaborateurVacance } from './collaborateursVacances.types'

export type CollaborateurVacancesState = Readonly<{
  entities: Entities<CollaborateurVacance>
}>

const initialState: CollaborateurVacancesState = {
  entities: {},
}

const collaborateursVacancesSlice = createSlice({
  name: 'collaborateursVacances',
  initialState,
  reducers: {
    updateAllCollaborateurVacances: (
      state,
      { payload }: PayloadAction<Record<string, CollaborateurVacance>>,
    ) => {
      state.entities = payload
    },
    addOrUpdateCollaborateurVacances: (
      state,
      { payload }: PayloadAction<Record<string, CollaborateurVacance>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    deleteCollaborateurVacances: (
      state,
      { payload: collaborateurVacanceId }: PayloadAction<string>,
    ) => {
      delete state.entities[collaborateurVacanceId]
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  updateAllCollaborateurVacances,
  addOrUpdateCollaborateurVacances,
  deleteCollaborateurVacances,
} = collaborateursVacancesSlice.actions

export const {
  reducer: collaborateursVacancesReducer,
} = collaborateursVacancesSlice
