import { AwsS3UploadParameters } from '@uppy/aws-s3'
import { normalizeAchats } from '~/store/achats/achats.normalize'
import { http } from '~/utils/httpClient'
import {
  normalizeCollaborateur,
  normalizeCollaborateurs,
} from './collaborateurs.normalize'
import {
  ApiCallNormalized,
  CollaborateurAchatsFetchParams,
  CollaborateurFromApi,
  CollaborateurListParams,
  CollaborateurUpdatePayload,
} from './collaborateurs.types'

export const fetchCollaborateursList = async (
  params: CollaborateurListParams = {},
): Promise<ApiCallNormalized> => {
  const collaborateurs = await http.$get('collaborateurs', { params })

  return normalizeCollaborateurs(collaborateurs)
}

export const fetchConnectedCollaborateur = async (): Promise<ApiCallNormalized> => {
  const collaborateur = await http.$get('collaborateurs/me')

  return normalizeCollaborateur(collaborateur)
}

export const updateCollaborateur = async (
  id: string,
  payload: CollaborateurUpdatePayload,
) => {
  const collaborateur = await http.$put<{
    collaborateur: CollaborateurFromApi
  }>(`collaborateurs/${id}`, payload)
  return normalizeCollaborateur(collaborateur)
}

export const updateCollaborateurAvatar = async (
  id: string,
  avatarPath: string,
) => {
  const collaborateur = await http.$put<{
    collaborateur: CollaborateurFromApi
  }>(`collaborateurs/${id}/avatar`, { avatarPath })
  return normalizeCollaborateur(collaborateur)
}

export const fetchCollaborateursAchatVenteList = async (): Promise<ApiCallNormalized> => {
  const collaborateurs = await http.$get('collaborateurs/achat-vente')

  return normalizeCollaborateurs(collaborateurs)
}

export const fetchCollaborateursLocationList = async (): Promise<ApiCallNormalized> => {
  const collaborateurs = await http.$get('collaborateurs/location')

  return normalizeCollaborateurs(collaborateurs)
}

export const getUploadParametersForCollaborateurAvatarUpdate = async (
  type: string,
) =>
  http.$get<AwsS3UploadParameters>('collaborateurs/upload-parameters', {
    params: { type },
  })

export const fetchCollaborateurAchats = async (
  collaborateurId: string,
  params: CollaborateurAchatsFetchParams,
) => {
  const {
    achats,
    meta,
  } = await http.$get(`collaborateurs/${collaborateurId}/achats`, { params })
  return {
    meta,
    data: normalizeAchats(achats),
  }
}

export const fetchCollaborateur = async (collaborateurId: string) => {
  const collaborateur = await http.$get<{
    collaborateur: CollaborateurFromApi
  }>(`collaborateurs/${collaborateurId}`)

  return normalizeCollaborateur(collaborateur)
}

export const deleteCollaborateur = async (collaborateurId: string) => {
  await http.$delete(`collaborateurs/${collaborateurId}`)
}
