import { Photo } from '@apimmo/front/utils/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight, prop, sortBy } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Bien, BienSurfaces } from '~/store/biens/biens.types'
import {
  DeleteFilePayload,
  Document as DocumentType,
} from '~/store/documents/documents.types'
import {
  removePathAndOrDocumentFromDocumentsList,
  removePhotosFromList,
} from '~/store/documents/documents.utils'
import { Estimation } from '~/store/estimations/estimations.types'
import { Piece } from '~/store/pieces/pieces.types'
import { Entities } from '~/store/selectors'
import { isBienFull } from './biens.utils'

export type BiensState = {
  entities: Entities<Bien>
}

const initialState: BiensState = {
  entities: {},
}

const biensSlice = createSlice({
  name: 'biens',
  initialState,
  reducers: {
    addOrUpdateBiens: (
      state,
      { payload }: PayloadAction<Record<string, Bien>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    addPhotosToBien(
      state,
      { payload }: PayloadAction<{ bienId: string; photos: Photo[] }>,
    ) {
      const { bienId, photos } = payload
      const bien = state.entities[bienId]
      if (!bien || !isBienFull(bien)) return

      bien.photos = bien.photos.concat(photos)
    },
    addEstimationToBien(
      state,
      { payload }: PayloadAction<{ bienId: string; estimation: Estimation }>,
    ) {
      const { bienId, estimation } = payload
      const bien = state.entities[bienId]
      if (!bien || !isBienFull(bien)) return

      bien.estimation = estimation
    },
    updatePieceOfBien(
      state,
      { payload }: PayloadAction<{ bienId: string; piece: Piece }>,
    ) {
      const { bienId, piece } = payload
      const bien = state.entities[bienId]
      if (!bien || !isBienFull(bien) || !bien.pieces) return

      bien.pieces = bien.pieces.map((currentPiece) => {
        if (currentPiece.id !== piece.id) return currentPiece
        return piece
      })
    },
    deleteDocumentOfBien(
      state,
      {
        payload,
      }: PayloadAction<DeleteFilePayload & { bienId: string; docId: string }>,
    ) {
      const { docId, path, bienId } = payload
      const bien = state.entities[bienId]
      if (!bien || !isBienFull(bien) || !bien.documents) return

      bien.documents = removePathAndOrDocumentFromDocumentsList(
        bien.documents,
        docId,
        path,
      )
    },
    addOrUpdateDocumentToBien: (
      state,
      { payload }: PayloadAction<{ document: DocumentType; bienId: string }>,
    ) => {
      const bien = state.entities[payload.bienId]
      if (!bien || !isBienFull(bien) || !bien.documents) return

      const documentsFiltered = bien.documents
        ? bien.documents.filter(
            ({ id, nom }) =>
              id !== payload.document.id && nom !== payload.document.nom,
          )
        : []
      documentsFiltered.push(payload.document)
      bien.documents = sortBy(prop('nom'))(documentsFiltered)
    },
    deletePhotosOfBienPiece: (
      state,
      {
        payload,
      }: PayloadAction<{
        bienId: string
        pieceId: string
        photoToRemoveIds: string[]
      }>,
    ) => {
      const { bienId, pieceId, photoToRemoveIds } = payload
      const bien = state.entities[bienId]

      if (!bien || !isBienFull(bien) || !bien.pieces) return

      const piece = bien.pieces.find((piece) => piece.id === pieceId)
      if (!piece || !piece.photos) return

      piece.photos = removePhotosFromList(piece.photos, photoToRemoveIds)
    },
    deletePhotosOfBien: (
      state,
      {
        payload,
      }: PayloadAction<{ bienId: string; photoToRemoveIds: string[] }>,
    ) => {
      const { bienId, photoToRemoveIds } = payload
      const bien = state.entities[bienId]
      if (!bien || !isBienFull(bien) || !bien.photos) return

      bien.photos = removePhotosFromList(bien.photos, photoToRemoveIds)
    },
    updateParcellesInBien: (
      state,
      {
        payload,
      }: PayloadAction<{
        bienId: string
        parcellesIds: string[]
        surfaceCadastrale?: number | null
      }>,
    ) => {
      const { bienId, parcellesIds, surfaceCadastrale } = payload
      const bien = state.entities[bienId]
      if (!bien || !isBienFull(bien)) return

      bien.parcelles = parcellesIds
      if (surfaceCadastrale) {
        bien.surfaceCadastrale = surfaceCadastrale
      }
    },
    updateSurfacesInBien: (
      state,
      {
        payload,
      }: PayloadAction<
        {
          bienId: string
        } & BienSurfaces
      >,
    ) => {
      const { bienId, surface, hasExterieur, surfaceTerrain } = payload
      const bien = state.entities[bienId]
      if (!bien || !isBienFull(bien)) return

      bien.surface = surface
      bien.hasExterieur = hasExterieur
      bien.surfaceTerrain = surfaceTerrain
    },
    updateDiffusionInBien: (
      state,
      { payload }: PayloadAction<{ bienId: string; diffusionId: string }>,
    ) => {
      const { bienId, diffusionId } = payload
      const bien = state.entities[bienId]
      if (!bien || !isBienFull(bien)) return

      bien.diffusion = diffusionId
    },
  },

  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  addOrUpdateBiens,
  addPhotosToBien,
  addEstimationToBien,
  updatePieceOfBien,
  deleteDocumentOfBien,
  addOrUpdateDocumentToBien,
  deletePhotosOfBienPiece,
  deletePhotosOfBien,
  updateDiffusionInBien,
  updateParcellesInBien,
  updateSurfacesInBien,
} = biensSlice.actions
export const { reducer: biensReducer } = biensSlice
