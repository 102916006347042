import Icon from '@apimmo/front/components/icons/Icon'
import { GridCell } from '@apimmo/front/components/layouts/Grid'
import { Text } from '@apimmo/front/components/Typography'
import { Icons, spacings, transition } from '@apimmo/front/style/theme'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import React, { FC } from 'react'
import { matchPath, useLocation } from 'react-router'
import { Link, LinkProps } from 'react-router-dom'
import { withProps } from 'recompose'
import { colors } from '~/style/theme'

export type BaseNavLinkProps = {
  isActive?: boolean
  icon?: Icons
  label: string
}

export const BaseNavLink = ({
  icon,
  label,
  isActive,
  ...props
}: BaseNavLinkProps) => (
  <GridCell
    direction="column"
    verticalAlignment="center"
    horizontalAlignment="start"
    {...props}
  >
    {icon && (
      <Icon
        name={icon}
        size="small"
        color={isActive ? 'primary' : 'secondaryLighter'}
      />
    )}
    <Text weight="semibold" color={isActive ? 'white' : 'secondaryLighter'}>
      {label}
    </Text>
  </GridCell>
)

type NavLinkProps = FC<BaseNavLinkProps & LinkProps & { as: typeof Link }>
export const NavLink = withProps({ as: Link })(BaseNavLink as NavLinkProps)

type MenuLinkProps = {
  to: string
  label: string
  icon: Icons
}

const StyledNavLink = styled(NavLink)<{ isActive: boolean }>`
  position: relative;
  padding: ${spacings.small} ${spacings.medium};
  transition: ${transition.global};
  gap: ${spacings.medium};

  &:hover {
    background-color: ${rgba(colors.secondaryLight, 0.3)};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      gap: ${spacings.small};
      background-color: ${rgba(colors.secondaryLight, 0.3)};
      &::after {
        content: '';
        width: 4px;
        height: 25px;
        background: ${colors.primary};
        position: absolute;
        right: 0;
        border-radius: 2.5px 0 0 2.5px;
      }
    `}
`

export const MenuLink = ({ to, label, icon }: MenuLinkProps) => {
  const { pathname } = useLocation()

  return (
    <StyledNavLink
      isActive={Boolean(matchPath(pathname, { path: to, exact: true }))}
      to={to}
      label={label}
      icon={icon}
    />
  )
}
