import { icons } from '@apimmo/front/style/icons'
import styled from '@emotion/styled'
import React from 'react'

const HiddenSprite = styled.svg`
  position: absolute;
`

export const Sprite = () => (
  <HiddenSprite width="0" height="0">
    {Object.entries(icons).map(([name, symbol]) =>
      React.cloneElement(symbol, {
        id: name,
        key: name,
      }),
    )}
  </HiddenSprite>
)
