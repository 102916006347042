import { Global } from '@emotion/react'
import React from 'react'
import { fontsCSS } from './fonts'
import { resetCSS } from './reset'

export const GlobalStyle = () => (
  <>
    <Global styles={resetCSS} />
    <Global styles={fontsCSS} />
  </>
)
