import { getNomComplet } from '@apimmo/front/utils/strings'
import { Collaborateur } from '~/store/collaborateurs/collaborateurs.types'

export const formatCollaborateursAsOptions = (
  collaborateurs: Collaborateur[],
) =>
  collaborateurs.map((collaborateur) => ({
    value: collaborateur.id,
    label: getNomComplet(collaborateur),
  }))
