import Icon from '@apimmo/front/components/icons/Icon'
import {
  borderRadius,
  fontFamily,
  Icons,
  lineHeight,
  spacings,
  transition,
} from '@apimmo/front/style/theme'
import { remCalc } from '@apimmo/front/utils/selectors'
import isPropValid from '@emotion/is-prop-valid'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { PulseLoader } from 'react-spinners'
import { colors } from '~/style/theme'

export type ButtonProps = {
  label: string
  level?: keyof typeof variants
  size?: keyof typeof variantsSize
  disabled?: boolean
  icon?: Icons
  iconPosition?: 'left' | 'right'
  loading?: boolean
} & JSX.IntrinsicElements['button']

type StyledButtonProps = {
  level?: keyof typeof variants
  size?: keyof typeof variantsSize
  iconPosition?: 'left' | 'right'
}

const variants = {
  primary: css`
    background-color: ${colors.primary};
    color: ${colors.white};
  `,
  secondary: css`
    background-color: ${colors.primaryLight};
    color: ${colors.primaryDark};
  `,
  hollow: css`
    background-color: transparent;
    color: ${colors.secondary};
    box-shadow: inset 0 0 0 2px ${colors.secondaryLight};
  `,
  ghost: css`
    background-color: transparent;
    color: ${colors.grey400};
  `,
  alert: css`
    background-color: ${colors.error};
    color: ${colors.white};
  `,
} as const

const variantsHover = {
  primary: css`
    background-color: ${colors.primaryDark};
    color: ${colors.white};
  `,
  secondary: css`
    background-color: ${colors.white};
    color: ${colors.primaryDark};
    box-shadow: inset 0 0 0 1px ${colors.primary};
  `,
  hollow: css`
    background-color: ${colors.secondaryLightness};
    color: ${colors.secondary};
  `,
  ghost: css`
    background-color: ${colors.grey50};
    color: ${colors.grey400};
  `,
  alert: css`
    background-color: ${colors.errorDark};
    color: ${colors.white};
  `,
} as const

const variantsFocus = (level: string) => {
  switch (level) {
    case 'hollow':
      return css`
        box-shadow: inset 0 0 0 2px ${colors.secondaryLight},
          white 0px 0px 0px 4px, ${colors.grey100} 0px 0px 0px 5px;
      `
    case 'secondary':
      return css`
        box-shadow: 0 0 0 1px ${colors.primary}, white 0px 0px 0px 4px,
          ${colors.grey100} 0px 0px 0px 5px;
      `
    default:
      return css`
        box-shadow: white 0px 0px 0px 4px, ${colors.grey100} 0px 0px 0px 5px;
      `
  }
}

export const variantsSize = {
  tiny: css`
    padding: ${remCalc(6)} ${remCalc(11)};
    font-size: ${remCalc(12)};
  `,
  small: css`
    padding: ${spacings.xsmall} ${spacings.small};
    font-size: ${remCalc(12)};
  `,
  large: css`
    padding: ${spacings.medium} ${spacings.large};
    font-size: ${remCalc(16)};
  `,
  default: css`
    padding: ${remCalc(12)} ${spacings.medium};
    font-size: 1rem;
  `,
} as const

export const BaseButton = css`
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  justify-self: start;
  gap: ${spacings.small};
  border: none;
  border-radius: ${borderRadius};
  font-family: ${fontFamily};
  font-weight: 600;
  text-align: center;
  transition: ${transition.global};
  cursor: pointer;
  outline: none;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &:focus {
    outline: none;
  }
`

const StyledButton = styled('button', {
  shouldForwardProp: isPropValid,
})<StyledButtonProps>`
  ${BaseButton}
  justify-content: center;
  ${({ level = 'primary' }) => variants[level]};
  ${({ size = 'default' }) => variantsSize[size]};

  ${({ iconPosition }) =>
    iconPosition === 'right' &&
    `
    &>*:first-child {
      order: 1;
    }
  `}

  &:not(:disabled):hover,
  &:not([disabled]):hover,
  &:not(:disabled):focus,
  &:not([disabled]):focus {
    ${({ level = 'primary' }) => variantsHover[level]};
  }

  &:not(:disabled):focus,
  &:not([disabled]):focus {
    ${({ level = 'primary' }) => variantsFocus(level)};
  }
`

const ButtonText = styled('span')`
  line-height: ${lineHeight.small};

  &::first-letter {
    text-transform: uppercase;
  }
`

const renderIcon = (loading: boolean, icon?: Icons) => {
  if (loading) {
    return <PulseLoader size={5} color={colors.primary} />
  }
  if (!icon) return null
  return <Icon name={icon} size={16} />
}
export const Button = ({
  level,
  disabled,
  size,
  icon,
  iconPosition,
  label,
  loading = false,
  ...otherProps
}: ButtonProps) => (
  <StyledButton
    {...otherProps}
    disabled={disabled}
    level={level}
    size={size}
    iconPosition={iconPosition}
  >
    {renderIcon(loading, icon)}
    <ButtonText>{label}</ButtonText>
  </StyledButton>
)
