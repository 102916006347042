import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Entities } from '~/store/selectors'
import { unique } from '~/utils/arrays'
import { Agence } from './agences.types'

export type AgencesState = Readonly<{
  entities: Entities<Agence>
}>

const initialState: AgencesState = {
  entities: {},
}

const agencesSlice = createSlice({
  name: 'agences',
  initialState,
  reducers: {
    updateAllAgences: (
      state,
      { payload }: PayloadAction<Record<string, Agence>>,
    ) => {
      state.entities = payload
    },
    addOrUpdateAgences: (
      state,
      { payload }: PayloadAction<Record<string, Agence>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    addAnnonceursToAgence: (
      state,
      { payload }: PayloadAction<{ agenceId: string; annonceurs: string[] }>,
    ) => {
      const agence = state.entities[payload.agenceId]

      if (!agence) return

      agence.annonceurs = unique([
        ...(agence.annonceurs || []),
        ...payload.annonceurs,
      ])
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  updateAllAgences,
  addOrUpdateAgences,
  addAnnonceursToAgence,
} = agencesSlice.actions
export const { reducer: agencesReducer } = agencesSlice
