import { curry } from 'ramda'
import { compact } from 'ramda-adjunct'
import { RootState } from '~/store/types'
import { groupItemsIdsByCreatedAtDate } from '~/utils/arrays'
import { TacheStatuts } from './taches.constants'
import { Tache } from './taches.type'

export const selectTacheById = curry(
  (tacheId: string, state: RootState) => state.taches.entities[tacheId],
)

export const selectPendingTachesIdsGroupedByDate = curry(
  (tachesIds: string[], state: RootState) => {
    const taches = compact(
      tachesIds.map((tacheId) => selectTacheById(tacheId, state)),
    ).filter((tache) => tache.statut === TacheStatuts.pending)

    return groupItemsIdsByCreatedAtDate(taches)
  },
)

export const selectTachesListFromIds = curry(
  (tachesIds: string[], state: RootState): Tache[] => {
    if (tachesIds.length === 0) return []

    return compact(tachesIds.map((id) => selectTacheById(id, state)))
  },
)
