import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { Entities } from '~/store/selectors'
import { Annonceur } from './annonceurs.types'

export type AnnonceursState = Readonly<{
  entities: Entities<Annonceur>
}>

const initialState: AnnonceursState = {
  entities: {},
}

const annonceursSlice = createSlice({
  name: 'annonceurs',
  initialState,
  reducers: {
    addOrUpdateAnnonceurs: (
      state,
      { payload }: PayloadAction<Record<string, Annonceur>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
})

export const { addOrUpdateAnnonceurs } = annonceursSlice.actions
export const { reducer: annonceursReducer } = annonceursSlice
