import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight, prop, sortBy, uniq } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Document as DocumentType } from '~/store/documents/documents.types'
import { Entities } from '~/store/selectors'
import { Compromis } from './compromis.types'

export type CompromisState = Readonly<{
  entities: Entities<Compromis>
}>

const initialState: CompromisState = {
  entities: {},
}

const compromisSlice = createSlice({
  name: 'compromis',
  initialState,
  reducers: {
    addOrUpdateCompromis: (
      state,
      { payload }: PayloadAction<Record<string, Compromis>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    addSignatureRendezVousToCompromis: (
      state,
      { payload }: PayloadAction<{ compromisId: string; rendezVousId: string }>,
    ) => {
      const compromis = state.entities[payload.compromisId]
      if (!compromis) return

      compromis.signatureRendezVous = payload.rendezVousId
    },
    addDisponibilitesToCompromis: (
      state,
      {
        payload,
      }: PayloadAction<{ compromisId: string; disponibilites: string[] }>,
    ) => {
      const compromis = state.entities[payload.compromisId]
      if (!compromis) return

      compromis.disponibilites = uniq([
        ...(compromis.disponibilites || []),
        ...payload.disponibilites,
      ])
    },
    addOrUpdateDocumentToCompromis: (
      state,
      {
        payload,
      }: PayloadAction<{ document: DocumentType; compromisId: string }>,
    ) => {
      const compromis = state.entities[payload.compromisId]
      if (!compromis) return

      const documentsFiltered = compromis.documents
        ? compromis.documents.filter(
            ({ id, nom }) =>
              id !== payload.document.id && nom !== payload.document.nom,
          )
        : []
      documentsFiltered.push(payload.document)
      compromis.documents = sortBy(prop('nom'))(documentsFiltered)
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  addOrUpdateCompromis,
  addDisponibilitesToCompromis,
  addSignatureRendezVousToCompromis,
  addOrUpdateDocumentToCompromis,
} = compromisSlice.actions
export const { reducer: compromisReducer } = compromisSlice
