import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { Entities } from '~/store/selectors'
import { Parcelle } from './parcelles.type'

export type ParcellesState = Readonly<{
  entities: Entities<Parcelle>
}>

const initialState: ParcellesState = {
  entities: {},
}

const parcellesSlice = createSlice({
  name: 'parcelles',
  initialState,
  reducers: {
    addOrUpdateParcelles: (
      state,
      { payload }: PayloadAction<Record<string, Parcelle>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
})
export const { addOrUpdateParcelles } = parcellesSlice.actions

export const { reducer: parcellesReducer } = parcellesSlice
