import { DependencyList, ReactNode, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Options, useToasts } from 'react-toast-notifications'
import {
  useAsync as useAsyncOriginal,
  useAsyncFn as useAsyncFnOriginal,
} from 'react-use'
import { FunctionReturningPromise, PromiseType } from 'react-use/lib/misc/types'
import { AsyncFnReturn, AsyncState } from 'react-use/lib/useAsyncFn'
import { paths } from '~/routes/routes'

export const useAppToasts = (hookOptions?: Partial<Options>) => {
  const { addToast } = useToasts()
  const successToast = (msg: ReactNode, options?: Partial<Options>) =>
    addToast(msg, {
      appearance: 'success',
      autoDismiss: true,
      ...hookOptions,
      ...options,
    })
  const errorToast = (msg: ReactNode, options?: Partial<Options>) =>
    addToast(msg, {
      appearance: 'error',
      autoDismiss: false,
      ...hookOptions,
      ...options,
    })

  return {
    successToast,
    errorToast,
  }
}

export const useHideAfterDelay = (delay: number): [boolean, () => void] => {
  const [display, setDisplay] = useState(false)

  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const displayAndHideAfterDelay = () => {
    setDisplay(true)
    if (timeout.current) clearTimeout(timeout.current)
    timeout.current = setTimeout(() => setDisplay(false), delay)
  }

  useEffect(() => timeout.current && clearTimeout(timeout.current), [])

  return [display, displayAndHideAfterDelay]
}

const useLogoutOnError = (error: Error | undefined) => {
  const { errorToast } = useAppToasts()
  const { push } = useHistory()

  useAsyncOriginal(async () => {
    // @ts-ignore response exists on error raised by axios
    if (error && error?.response?.status === 401) {
      push(paths.logout)
      errorToast('Vous avez été déconnecté.')
    }
  }, [error])
}

const useDisplayUnauthorizedError = (error: Error | undefined) => {
  const { errorToast } = useAppToasts()

  useAsyncOriginal(async () => {
    // @ts-ignore response exists on error raised by axios
    if (error && error?.response?.status === 403) {
      errorToast("Vous n'êtes pas autorisés à faire cette action.")
    }
  }, [error])
}

type StateFromFunctionReturningPromise<
  T extends FunctionReturningPromise
> = AsyncState<PromiseType<ReturnType<T>>>

export const useAsyncFn = <T extends FunctionReturningPromise>(
  fn: T,
  deps: DependencyList = [],
  initialState: StateFromFunctionReturningPromise<T> = { loading: false },
): AsyncFnReturn<T> => {
  const res = useAsyncFnOriginal(fn, deps, initialState)
  const [{ error }] = res

  useDisplayUnauthorizedError(error)
  useLogoutOnError(error)

  return res
}

export const useAsync = <T extends FunctionReturningPromise>(
  fn: T,
  deps: DependencyList = [],
) => {
  const res = useAsyncOriginal(fn, deps)
  const { error } = res

  useDisplayUnauthorizedError(error)
  useLogoutOnError(error)

  return res
}
