import Icon from '@apimmo/front/components/icons/Icon'
import { Sprite } from '@apimmo/front/components/icons/Sprite'
import { Grid, GridCell } from '@apimmo/front/components/layouts/Grid'
import { H1, H2, TextLarge } from '@apimmo/front/components/Typography'
import React from 'react'
import { Button } from '~/components/common/Buttons/Button'
import { GlobalStyle } from '~/style/GlobalStyle'

export const ErrorDisplay = () => (
  <div>
    <GlobalStyle />
    <Sprite />
    <Grid
      gap="large"
      horizontalAlignment="center"
      verticalAlignment="center"
      columns="40% 30%"
      rows="40px auto"
    >
      <GridCell row={2} gap="small">
        <header>
          <H1>Oops !</H1>
          <H2>Une erreur est survenue</H2>
        </header>
        <TextLarge>
          N&apos;hésitez pas à rafraichir votre page en cliquant sur le bouton
          ci-dessous.
          <br />
          Si le problème persiste, merci de vous rapprocher du service support.
        </TextLarge>
        <Button
          onClick={() => window.location.reload()}
          label="Recharger la page"
        />
      </GridCell>
      <GridCell row={2} horizontalAlignment="center">
        <Icon name="errorPage" size={300} />
      </GridCell>
    </Grid>
  </div>
)
