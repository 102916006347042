import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'react-router-dom'
import { paths } from '~/routes/routes'
import { condition, getRemProp } from '~/utils/selectors'

type LogoProps = {
  width?: number
}

const StyledSVG = styled.svg<LogoProps>`
  display: block;
  width: ${condition('width', getRemProp('width'), '100%')};
`

const StyledLink = styled(Link)`
  display: block;
`

export const Logo = ({ width, ...props }: LogoProps) => (
  <StyledLink to={paths.home} {...props}>
    <StyledSVG
      width={width}
      viewBox="0 0 4068.98 1837.48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Papiris</title>
      <g>
        <path
          fill="#fff"
          d="M150.66,3736.36H418.75q166.48,0,257.83,64.86t91.36,181.61q0,73.52-44.87,128.66t-122.16,84.86q-77.3,29.72-171.34,29.72H384.16v254H150.66V3736.36ZM519.28,4052q31.36-23.77,31.36-69.19t-30.81-68.09q-30.8-22.69-89.19-22.7H384.16v181.61q21.61,2.16,46.48,2.16Q487.92,4075.8,519.28,4052Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M956,3736.36h328.64l215.13,743.75h-254l-28.09-142.7H1025.21l-31.34,142.7H750.63Zm235.67,468.08-70.27-325.39-73.52,325.39h143.78Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M1529,3736.36h268.09q166.48,0,257.83,64.86t91.36,181.61q0,73.52-44.87,128.66t-122.16,84.86q-77.3,29.72-171.34,29.72h-45.41v254H1529V3736.36ZM1897.59,4052q31.36-23.77,31.36-69.19t-30.81-68.09q-30.8-22.69-89.19-22.7h-46.48v181.61q21.61,2.16,46.48,2.16Q1866.23,4075.8,1897.59,4052Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M2188.38,3736.36H2438.1v743.75H2188.38V3736.36Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M2851,4309.32L2824,4198h-50.81v282.16H2536.47V3736.36H2864q93,0,163.77,27.56t109.73,77.3q38.91,49.73,38.92,114.59,0,72.42-35.14,121.61T3032.67,4158a759.2,759.2,0,0,0,17.3,74.59q13,41.09,35.67,57.3t65.94,16.22q15.14,0,47.56-4.33V4478q-50.81,19.45-99.45,19.45Q2896.44,4497.41,2851,4309.32Zm-36.75-262.7q80,0,113-17.83t33-61.08q0-36.75-27-53.52t-85.39-16.77h-74.59v149.19h41.08Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M3291,3736.36h249.72v743.75H3291V3736.36Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M3654.25,4441.19l54.06-167.56q111.33,40,198.91,40,47.55,0,69.72-11.34t22.17-34.06q0-15.12-11.89-26.47t-47.56-25.41q-35.67-14.06-107-34.59-87.56-25.95-136.22-84.86t-48.64-135.69q0-118.9,78.38-180t211.34-61.08a639,639,0,0,1,136.2,14.59q67,14.6,118.92,42.7l-58.37,163.23q-60.54-20.53-105.41-29.73a433.41,433.41,0,0,0-87-9.19q-71.34,0-71.34,38.92,0,19.45,23.23,33t95.67,34q113.51,30.28,171.89,92.44t58.38,148.64q0,120-77.84,184.33t-230.25,64.31Q3759.11,4497.41,3654.25,4441.19Z"
          transform="translate(-150.66 -2659.93)"
        />
      </g>
      <g>
        <path
          fill="#fff"
          d="M2771.92,2915.29s69.79,31.65,92.91,83.38c0,0-185.6,105.24-344.09,429.56l-38.27,5.13a3.91,3.91,0,0,1-4.63-4.83S2515.07,3217.55,2771.92,2915.29Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M2601.79,2832.37s77.27,30,115.05,51.3c0,0-156.2,183.82-279,574.33l-29.19,25.17c-2.36,2-4.21,1.15-4.1-2C2404.55,3481.21,2414.16,3193.06,2601.79,2832.37Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#ebbc00"
          d="M2404,2785.85s108.73,16.58,140.37,38.56c0,0-156.83,313.16-173.89,642.58l-36.58-33.32a16.38,16.38,0,0,1-4.59-9.47S2298.32,2988.33,2404,2785.85Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M2135.93,2806.84a7.58,7.58,0,0,1,3.88-8s75.7-36.9,190.49-4.83c0,0-72.28,447.12-25.69,588.16l-30.1,32.08c-2.13,2.27-4.43,1.64-5.1-1.4Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M1915.21,2873.41s36.8-43.84,138.59-56.33c0,0,170.28,320.53,200.25,614.3l-27.37,38.54c-1.81,2.54-4.15,2.22-5.21-.71C2221.45,3469.22,2049,2993.49,1915.21,2873.41Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M2190.3,3480c2.93,1.08,4.78-.53,4.12-3.58,0,0-67.71-314.38-346.29-568.88,0,0-59.37,25.2-79.24,59.1,0,0,240.16,171.11,390.44,501.93Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M1678.18,3028.87s316.08,188.82,417.23,429.36l28.37,10.47c2.93,1.08,4.48-.44,3.45-3.39,0,0-76.23-217.84-402-472.1C1725.21,2993.22,1699.55,3002.89,1678.18,3028.87Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M1618.76,2972.35s574.53-684.19,1313.78-27.56c0,0,25.64,54.63-55.45,37.72,0,0-550.67-586.94-1198.74,9.07C1678.35,2991.59,1632.76,3031.61,1618.76,2972.35Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M2298.62,3446.42c-0.14-3.12-2.19-4-4.56-2,0,0-41,35-49.24,109.64,0,0-42.82-59.45-139.51-56.66,0,0,22.1,132.63,204.44,203.08Z"
          transform="translate(-150.66 -2659.93)"
        />
        <path
          fill="#fff"
          d="M2315.54,3446.42c0.14-3.11,2.24-4,4.65-2.08,0,0,43,35.14,51.73,109.74,0,0,44.88-59.45,146.22-56.66,0,0-23.16,132.63-214.26,203.08Z"
          transform="translate(-150.66 -2659.93)"
        />
      </g>
    </StyledSVG>
  </StyledLink>
)
