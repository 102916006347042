import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight, omit } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Entities } from '~/store/selectors'
import { RendezVous } from './rendezVous.types'

export type RendezVousState = Readonly<{
  entities: Entities<RendezVous>
}>

const initialState: RendezVousState = {
  entities: {},
}

const rendezVousSlice = createSlice({
  name: 'rendezVous',
  initialState,
  reducers: {
    updateAllRendezVous: (
      state,
      { payload }: PayloadAction<Record<string, RendezVous>>,
    ) => {
      state.entities = payload
    },
    addOrUpdateRendezVous: (
      state,
      { payload }: PayloadAction<Record<string, RendezVous>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    deleteSeveralRendezVousById: (
      state,
      { payload: rendezVousIds }: PayloadAction<string[]>,
    ) => {
      state.entities = omit(rendezVousIds, state.entities)
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  updateAllRendezVous,
  addOrUpdateRendezVous,
  deleteSeveralRendezVousById,
} = rendezVousSlice.actions
export const { reducer: rendezVousReducer } = rendezVousSlice
