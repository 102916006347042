import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight, uniq } from 'ramda'
import { deleteAchat } from '~/store/achats/achats.slice'
import { logOut } from '~/store/auth/auth.slice'
import { DeleteFilePayload } from '~/store/documents/documents.types'
import { removePathAndOrDocumentFromDocumentsList } from '~/store/documents/documents.utils'
import { Entities } from '~/store/selectors'
import { deleteVente } from '~/store/ventes/ventes.slice'
import { unique } from '~/utils/arrays'
import { Contact } from './contacts.types'

export type ContactsState = Readonly<{
  entities: Entities<Contact>
}>

const initialState: ContactsState = {
  entities: {},
}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    updateAllContacts: (
      state,
      { payload }: PayloadAction<Record<string, Contact>>,
    ) => {
      state.entities = payload
    },
    addOrUpdateContacts: (
      state,
      { payload }: PayloadAction<Record<string, Contact>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    removeContactById: (
      state,
      { payload: contactId }: PayloadAction<string>,
    ) => {
      delete state.entities[contactId]
    },

    addRendezVousToContact: (
      state,
      {
        payload,
      }: PayloadAction<{ contactId: string; rendezVousIds: string[] }>,
    ) => {
      const contact = state.entities[payload.contactId]
      if (!contact) return
      if (!contact.rendezVous) {
        contact.rendezVous = payload.rendezVousIds
      } else {
        contact.rendezVous = uniq([
          ...contact.rendezVous,
          ...payload.rendezVousIds,
        ])
      }
    },
    addVenteToContacts: (
      state,
      { payload }: PayloadAction<{ contactsIds: string[]; venteId: string }>,
    ) => {
      const { contactsIds, venteId } = payload
      contactsIds.forEach((contactId) => {
        const contact = state.entities[contactId]
        if (!contact || !contact.ventes) return
        contact.ventes = unique([...contact?.ventes, venteId])
      })
    },
    addAchatToContacts: (
      state,
      { payload }: PayloadAction<{ contactIds: string[]; achatId: string }>,
    ) => {
      const { contactIds, achatId } = payload
      contactIds.forEach((contactId) => {
        const contact = state.entities[contactId]
        if (!contact) return
        contact.achats = unique([...(contact?.achats ?? []), achatId])
      })
    },
    addAchatsToContact: (
      state,
      { payload }: PayloadAction<{ achatsIds: string[]; contactId: string }>,
    ) => {
      const { achatsIds, contactId } = payload
      const contact = state.entities[contactId]
      if (!contact) return
      contact.achats = unique((contact.achats || []).concat(achatsIds))
    },
    addVentesToContact: (
      state,
      { payload }: PayloadAction<{ ventesIds: string[]; contactId: string }>,
    ) => {
      const { ventesIds, contactId } = payload
      const contact = state.entities[contactId]
      if (!contact) return
      contact.ventes = unique((contact.ventes || []).concat(ventesIds))
    },
    addNotaireToContact: (
      state,
      { payload }: PayloadAction<{ notaireId: string; contactId: string }>,
    ) => {
      const { notaireId, contactId } = payload
      const contact = state.entities[contactId]
      if (!contact) return
      contact.notaire = notaireId
    },
    deleteDocumentOfContact(
      state,
      {
        payload,
      }: PayloadAction<
        DeleteFilePayload & { contactId: string; docId: string }
      >,
    ) {
      const { docId, path, contactId } = payload
      const contact = state.entities[contactId]
      if (!contact) return

      contact.documents = removePathAndOrDocumentFromDocumentsList(
        contact.documents,
        docId,
        path,
      )
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
    [deleteAchat.type]: (
      state,
      { payload: achatId }: PayloadAction<string>,
    ) => {
      const contactIds = Object.keys(state.entities)
      contactIds.forEach((contactId) => {
        if (!state.entities[contactId] || !state.entities[contactId]?.achats)
          return

        // @ts-ignore
        state.entities[contactId].achats = state.entities[
          contactId
        ].achats.filter((achat) => achat !== achatId)
      })
    },
    [deleteVente.type]: (
      state,
      { payload: venteId }: PayloadAction<string>,
    ) => {
      const contactIds = Object.keys(state.entities)
      contactIds.forEach((contactId) => {
        if (!state.entities[contactId] || !state.entities[contactId]?.ventes)
          return

        // @ts-ignore
        state.entities[contactId].ventes = state.entities[
          contactId
        ].ventes.filter((vente) => vente !== venteId)
      })
    },
  },
})

export const {
  updateAllContacts,
  addOrUpdateContacts,
  removeContactById,
  addVenteToContacts,
  addAchatToContacts,
  addAchatsToContact,
  addRendezVousToContact,
  addVentesToContact,
  addNotaireToContact,
  deleteDocumentOfContact,
} = contactsSlice.actions
export const { reducer: contactsReducer } = contactsSlice
