import { Spacing, spacings } from '@apimmo/front/style/theme'
import styled from '@emotion/styled'

export type GridFlexProps = {
  type?: 'block' | 'inline'
  verticalAlignment?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'stretch'
    | 'baseline'
  horizontalAlignment?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'stretch'
    | 'baseline'
  gap?: Spacing
  wrap?: 'wrap' | 'wrap-reverse' | 'nowrap'
}

const GridFlex = styled.div<GridFlexProps>`
  display: ${({ type }) => (type === 'inline' ? 'inline-flex' : 'flex')};
  flex-wrap: ${({ wrap }) => wrap && wrap};
`

export const RowFlex = styled(GridFlex)`
  flex-direction: row;
  align-items: ${({ verticalAlignment = 'start' }) => verticalAlignment};
  justify-content: ${({ horizontalAlignment = 'start' }) =>
    horizontalAlignment};
  gap: ${({ gap }) => gap && spacings[gap]};
`

export const ColumnFlex = styled(GridFlex)`
  flex-direction: column;
  align-items: ${({ horizontalAlignment = 'start' }) => horizontalAlignment};
  justify-content: ${({ verticalAlignment = 'start' }) => verticalAlignment};
  gap: ${({ gap }) => gap && spacings[gap]};
`
