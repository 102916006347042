import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { Entities } from '~/store/selectors'
import { ActeVente } from './actesVente.types'

export type ActeVenteState = Readonly<{
  entities: Entities<ActeVente>
}>

const initialState: ActeVenteState = {
  entities: {},
}

const actesVenteSlice = createSlice({
  name: 'actesVente',
  initialState,
  reducers: {
    addOrUpdateActeVente: (
      state,
      { payload }: PayloadAction<Record<string, ActeVente>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    addSignatureRendezVousToActeVente: (
      state,
      { payload }: PayloadAction<{ acteVenteId: string; rendezVousId: string }>,
    ) => {
      const acteVente = state.entities[payload.acteVenteId]
      if (!acteVente) return

      acteVente.signatureRendezVous = payload.rendezVousId
    },
  },
})
export const {
  addOrUpdateActeVente,
  addSignatureRendezVousToActeVente,
} = actesVenteSlice.actions

export const { reducer: actesVenteReducer } = actesVenteSlice
