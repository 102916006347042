// @ts-nocheck
import { path, pipe, values } from 'ramda'
import { Achat } from '~/store/achats/achats.types'
import { Bien } from '~/store/biens/biens.types'
import { Collaborateur } from '~/store/collaborateurs/collaborateurs.api'
import { CollaborateurVacance } from '~/store/collaborateursVacances/collaborateursVacances.types'
import { Contact } from '~/store/contacts/contacts.types'
import { Mandat } from '~/store/mandats/mandats.types'
import { RendezVous } from '~/store/rendezVous/rendezVous.types'
import { RootState } from '~/store/types'
import { Vente } from '~/store/ventes/ventes.types'
import { Visite } from '~/store/visites/visites.types'
import { Merge } from '~/utils/types'

type ModelsTypesMapping = {
  achats: Achat
  collaborateurs: Collaborateur
  collaborateursVacances: CollaborateurVacance
  contacts: Contact
  ventes: Vente
  biens: Bien
  rendezVous: RendezVous
  mandats: Mandat
  visites: Visite
}

export const getListOf = <K extends keyof ModelsTypesMapping>(
  key: K,
  state: RootState,
): ModelsTypesMapping[K][] => pipe(path([key, 'entities']), values)(state)

export type Entities<NormalizedObj extends { id: string }> = Record<
  NormalizedObj['id'],
  NormalizedObj | undefined
>

export type Normalized<
  // eslint-disable-next-line @typescript-eslint/ban-types
  Model extends object,
  // eslint-disable-next-line @typescript-eslint/ban-types
  Relation extends { [P in keyof Model]?: string | string[] } = {}
> = Merge<Model, Relation>
