import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { MandatAvenantsEntities } from './mandatAvenants.types'

export type MandatAvenantsState = Readonly<{
  entities: MandatAvenantsEntities
}>

const initialState: MandatAvenantsState = {
  entities: {},
}

const mandatAvenantsSlice = createSlice({
  name: 'mandatAvenants',
  initialState,
  reducers: {
    updateAllMandatAvenants: (
      state,
      { payload }: PayloadAction<MandatAvenantsEntities>,
    ) => {
      state.entities = payload
    },
    addOrUpdateMandatAvenants: (
      state,
      { payload }: PayloadAction<MandatAvenantsEntities>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    deleteMandatAvenant: (
      state,
      { payload: mandatAvenantId }: PayloadAction<string>,
    ) => {
      delete state.entities[mandatAvenantId]
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  updateAllMandatAvenants,
  addOrUpdateMandatAvenants,
  deleteMandatAvenant,
} = mandatAvenantsSlice.actions
export const { reducer: mandatAvenantsReducer } = mandatAvenantsSlice
