import { fonts as frontFonts } from '@apimmo/front/style/fonts'
import { css } from '@emotion/react'

const fonts = {
  ...frontFonts,
}

export const fontsCSS = () => css`
  ${fonts.definitions.map(
    (definition) => css`
      @font-face {
        font-family: '${fonts.name}';
        src: url(${definition.pathWoff2}) format('woff2'),
          url(${definition.pathWoff}) format('woff');
        font-weight: ${definition.weight || 'normal'};
        font-style: ${definition.style || 'normal'};
      }
    `,
  )}
`
