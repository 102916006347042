import { spacings } from '@apimmo/front/style/theme'
import styled from '@emotion/styled'
import React from 'react'

type PictureProps = {
  size?: keyof typeof spacings
}

type AvatarProps = {
  alt: string
  src: string
} & PictureProps

const Picture = styled.div<PictureProps>`
  border-radius: 50%;
  width: ${({ size = 'large' }) => size && spacings[size]};
  height: ${({ size = 'large' }) => size && spacings[size]};
  overflow: hidden;
`

const PictureTag = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Avatar = ({ alt, src, ...otherProps }: AvatarProps) => (
  <Picture {...otherProps}>
    <PictureTag src={src} alt={alt} />
  </Picture>
)

export default Avatar
