import { comparator, curry, identity, sort } from 'ramda'
import { compact } from 'ramda-adjunct'
import { selectActiviteEffectueeById } from '~/store/activitesEffectuees/activitesEffectuees.selector'
import { ActiviteEffectuee } from '~/store/activitesEffectuees/activitesEffectuees.types'
import { selectContactById } from '~/store/contacts/contacts.selectors'
import { selectLastOffrePropositionByOffreId } from '~/store/offrePropositions/offrePropositions.selectors'
import { OffrePropositionStatut } from '~/store/offrePropositions/offrePropositions.types'
import { selectSortedOffresByAchatId } from '~/store/offres/offres.selectors'
import { selectTachesListFromIds } from '~/store/taches/taches.selectors'
import { RootState } from '~/store/types'
import { selectVenteById } from '~/store/ventes/ventes.selectors'
import { selectVisiteById } from '~/store/visites/visites.selectors'
import { ApporteurAffaires } from '../apporteurAffaires/apporteurAffaires.types'
import { tachableType } from '../taches/taches.constants'
import { ExistingAchat, Variant } from './achats.types'
import { isExistingAchat } from './achats.utils'

export const selectAchatById = curry(
  (achatId: string, { achats }: RootState) => achats.entities[achatId],
)

export const selectSortedAchatsByCloturedAtFromContact = curry(
  (contactId: string, variant: Variant|undefined, state: RootState) => {
    const contact = selectContactById(contactId, state)
    if (!contact || !contact.achats) return []

    return sort(
      comparator(
        (achat1, achat2) => (achat1.clotureAt || '') < (achat2.clotureAt || ''),
      ),
      compact(
        contact.achats
        .map((achatId) => selectAchatById(achatId, state))
        .filter((achat) => variant ? (achat?.variant ?? Variant.achatvente) === variant : true),
      ) as ExistingAchat[],
    )
  },
)

export const selectContactAchatIds = curry(
  (contactId: string, state: RootState) => {
    const contact = selectContactById(contactId, state)
    if (!contact) return null
    return contact.achats
  },
)

export const selectAchatsCountByContact = curry(
  (contactId: string, variant: Variant|undefined, state: RootState) => {
    const achatIds = selectContactAchatIds(contactId, state)
    if (!achatIds) return 0

    return achatIds
      .map((achatId) => {
        const achat = selectAchatById(achatId, state)
        if (!achat || !isExistingAchat(achat)) return null
        return achat
      })
      .filter(identity)
      .filter((achat) => variant ? (achat?.variant ?? Variant.achatvente) === variant : true)
      .length
  },
)

export const selectIsVenteInAchatVisites = curry(
  (venteId: string, achatId: string, state: RootState) => {
    const achat = selectAchatById(achatId, state)

    if (!achat || !isExistingAchat(achat)) return false

    const visites = achat.visites.map((visite) =>
      selectVisiteById(visite, state),
    )

    const ventesInVisites = visites.map((visite) => visite?.vente)

    return ventesInVisites.includes(venteId)
  },
)

export const selectCanCreateNewOffre = curry(
  (venteId: string, achatId: string, state: RootState) => {
    const achat = selectAchatById(achatId, state)
    const vente = selectVenteById(venteId, state)

    if (!achat || !vente) return true

    const offres = selectSortedOffresByAchatId(achat.id, state)

    const offreWithCurrentMandat = offres.find(
      (offre) => offre.mandatId === vente.mandatId,
    )

    if (!offreWithCurrentMandat) return true

    const lastProposition = selectLastOffrePropositionByOffreId(
      offreWithCurrentMandat.id,
      state,
    )

    return (
      lastProposition &&
      lastProposition.statut === OffrePropositionStatut.annulee
    )
  },
)

export const selectAchatsFromIds = curry(
  (achatsId: string[], state: RootState) =>
    achatsId.map((id) => selectAchatById(id, state)),
)

export const selectAchatLastActiviteEffectuee = curry(
  (achatId: string, state: RootState) => {
    const achat = selectAchatById(achatId, state)

    if (!achat || !isExistingAchat(achat) || !achat.activitesEffectuees)
      return null

    const activites = achat.activitesEffectuees.map((id) =>
      selectActiviteEffectueeById(id, state),
    ) as ActiviteEffectuee[]

    return activites.sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
    )[0]
  },
)

export const selectAchatIdsFromTasksIds = curry(
  (tachesIds: string[], state: RootState): string[] => {
    const taches = selectTachesListFromIds(tachesIds, state)

    const achatTaches = taches.filter(
      (tache) => tache.tachableType === tachableType.achat,
    )

    const ids = achatTaches.map((tache) => tache.tachableId)

    return ids
  },
)

export const selectApporteurAffairesByAchatId = curry(
  (achatId: string, state: RootState): ApporteurAffaires | null => {
    const achat = selectAchatById(achatId, state)

    if (!achat) return null
    return {
      apporteurAffairesId: achat.apporteurAffairesId,
      apporteurAffairesType: achat.apporteurAffairesType,
    }
  },
)
