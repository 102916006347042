import { defaultFontSize } from '@apimmo/front/style/theme'
import { SerializedStyles } from '@emotion/react'
import { StyledComponent } from '@emotion/styled'
import { rem } from 'polished'

type Props = Record<string, any>

const fallbackSymbol = Symbol('conditionFallback')

type FunctionCondition<T> = (args: T) => any
type Selector<T> = (props: T) => string | number | null | undefined | boolean
type SelectorProps<T> = T & Props
type ConditionRule<T> = string | number | Selector<T> | SerializedStyles
type ObjectCondition<T> = {
  [key: string]: ConditionRule<T>
  [fallbackSymbol]?: ConditionRule<T>
}

const execOrValue = <T>(value: ConditionRule<T> | undefined, args: T) =>
  typeof value === 'function' ? value(args) : value

/**
 * This functions helps you apply conditional styles depending on props presence (not value).
 * It can be used in 3 different ways :
 * - 2/3 arguments to make if/then or if/then/else condition
 * - 1 object argument to apply styles like a `switch` condition
 *
 * In the `if` style condition, you can pass a prop name or a function that will take as argument the props of
 * the component. Then/else values can be selectors or plain javascript values (string, number, etc).
 *
 * In the `switch` style condition, each key must be a prop name to look for. If no prop is matching it will look
 * for [condition.fallback] key (special symbol)
 * .
 * @example
 *   ```
 *   display: ${condition('hidden', 'none', 'block')};
 *   width: ${condition(${({ foo, bar }) => foo && bar}, '100%', getRemProp('size')};
 *   color: ${condition({ warning: 'orange', error: 'red', [condition.fallback]: 'black' })};
 *   ```
 */
export const condition = <T>(
  predicate:
    | keyof T
    | ObjectCondition<SelectorProps<T>>
    | FunctionCondition<SelectorProps<T>>,
  rule?: ConditionRule<SelectorProps<T>>,
  fallback?: ConditionRule<SelectorProps<T>>,
) => (args: SelectorProps<T>) => {
  if (typeof predicate === 'object') {
    const match = Object.entries(predicate).find(([key]) => args[key])
    return match && match[0]
      ? execOrValue(match[1], args)
      : execOrValue(predicate[fallbackSymbol], args)
  }

  const isTrue =
    typeof predicate === 'function' ? !!predicate(args) : args[predicate]
  return isTrue ? execOrValue(rule, args) : execOrValue(fallback, args)
}
condition.fallback = fallbackSymbol

/**
 * Returns the value of the specified prop or defaultValue if not found.
 */
export const getProp = <T extends Props>(
  propName: keyof T | (keyof T)[],
  defaultValue?: string | number,
) => (props: T): string | number | undefined => {
  if (Array.isArray(propName)) {
    const matchingProps = propName.find(
      (key) => typeof props[key] !== 'undefined',
    )
    return matchingProps ? props[matchingProps] : defaultValue
  }
  return props[propName] || defaultValue
}

/**
 * Same as getProp but converts the prop value to rem. If prop is not found, returns 0rem.
 */
export const getRemProp = <T>(
  propName: keyof T,
  defaultValue: string | number = 0,
) => (props: T) =>
  rem(
    props[propName] ? ((props as T)[propName] as any) : defaultValue,
    defaultFontSize,
  )

/**
 * This function serves as a replacement for Emotion `css` function which does not forward
 * props and does not support function injection. `chain` returns a function which is ready to be injected
 * in a styled component or used within other selector function like `condition`.
 *
 * @example:
 *   ```tsx
 *   const myAwesomeMixin = chain`width: ${getProp('size', 10)}`;
 *
 *   const Button = styled.button`${myAwesomeMixin}`;
 *   ```
 */
export const chain = <T>(
  strings: TemplateStringsArray,
  ...keys: (number | string | Selector<T> | StyledComponent<any, any, any>)[]
) => (props: T) =>
  strings.reduce(
    (finalString, string, i) =>
      `${finalString}${string}${
        typeof keys[i] === 'function'
          ? (keys[i] as Selector<T>)(props)
          : keys[i] || ''
      }`,
    '',
  )
