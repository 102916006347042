export const contactTacheType = [
  'Rappeler le client',
  'Envoyer un document',
  'Envoyer un e-mail',
]

export enum TacheStatuts {
  'pending' = 'En attente',
  'done' = 'Terminée',
}

export enum tachableType {
  'contact' = 'contact',
  'renseignement' = 'demandeRenseignements',
  'visite' = 'visite',
  'contreVisite' = 'contreVisite',
  'achat' = 'achat',
}
