export enum OffrePropositionStatut {
  attente = 'En attente',
  acceptee = 'Acceptée',
  refusee = 'Refusée',
  annulee = 'Annulée',
}

export type OffreProposition = {
  id: string
  createdAt: string
  prixAchat: number
  commission: number
  fraisNotaire: number
  budgetTravaux: number
  statut: OffrePropositionStatut
}

export type CreateOffrePropositionPayload = {
  prixAchat: string | number
  commission: string | number
  fraisNotaire: string | number
  budgetTravaux: string | number
}
