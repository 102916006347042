import { sentryDSN } from '@apimmo/front/utils/config'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'
import configureStore from '~/store/store'
import { Root } from './Root'

const rootEl = document.getElementById('root')

if (sentryDSN) {
  Sentry.init({ dsn: sentryDSN })
}

const { store, persistor } = configureStore()

if (rootEl) {
  ReactDOM.render(<Root store={store} persistor={persistor} />, rootEl)
}

if (module.hot) {
  module.hot.accept('./Root', () => {
    const NextRoot = require('./Root').Root // eslint-disable-line
    if (rootEl) {
      ReactDOM.render(<NextRoot store={store} persistor={persistor} />, rootEl)
    }
  })
}
