import { curry, prop, sortBy } from 'ramda'
import { compact } from 'ramda-adjunct'
import { Contact } from '~/store/contacts/contacts.types'
import { RootState } from '~/store/types'
import { unique } from '~/utils/arrays'

export const selectNotaireById = curry(
  (notaireId: string, state: RootState) => state.notaires.entities[notaireId],
)

export const selectUniqueNotairesByContacts = curry(
  (contacts: Contact[], state: RootState) =>
    sortBy(
      prop('nom'),
      unique(
        compact(
          contacts.map((contact) =>
            selectNotaireById(contact?.notaire || '', state),
          ),
        ),
      ),
    ),
)
