import { head, pipe, values } from 'ramda'

export const get = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  obj: object,
  path: string | string[],
  defaultValue: any = null,
): any => {
  const [firstPart, ...otherParts] = Array.isArray(path)
    ? path
    : path.split('.')

  const value = obj[firstPart as keyof typeof obj]

  if (value === undefined || value === null) {
    return defaultValue
  }

  if (!otherParts.length) {
    return value
  }

  return get(
    obj[firstPart as keyof typeof obj],
    otherParts.join('.'),
    defaultValue,
  )
}

interface GetFirst {
  <T extends any>(list: readonly T[]): T | undefined
  // eslint-disable-next-line @typescript-eslint/ban-types
  <T extends object, K extends keyof T>(obj: T): T[K]
}

export const getFirst: GetFirst = pipe(values, head)
