import Avatar from '@apimmo/front/components/Avatar'
import Icon from '@apimmo/front/components/icons/Icon'
import { Text, TextSecondarySmall } from '@apimmo/front/components/Typography'
import { RowFlex } from '@apimmo/front/components/layouts/GridFlex'
import { getNomComplet } from '@apimmo/front/utils/strings'
import styled from '@emotion/styled'
import React from 'react'
import { ButtonAsLink } from '~/components/common/Link/ButtonAsLink'
import { paths } from '~/routes/routes'
import { selectConnectedCollaborateur } from '~/store/collaborateurs/collaborateurs.selectors'
import { useSelector } from '~/store/utils'

const UserDetails = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const AppUserMenu = () => {
  const collaborateur = useSelector(selectConnectedCollaborateur)
  if (!collaborateur) {
    return <div />
  }

  return (
    <RowFlex
      horizontalAlignment="flex-end"
      verticalAlignment="center"
      gap="small"
    >
      {collaborateur.avatar ? (
        <Avatar
          size="xlarge"
          alt={getNomComplet(collaborateur, false)}
          src={collaborateur.avatar}
        />
      ) : (
        <Icon size="large" color="primary" name="user" />
      )}
      <UserDetails>
        <Text weight="semibold">{getNomComplet(collaborateur, false)}</Text>
        <TextSecondarySmall>{collaborateur.fonction}</TextSecondarySmall>
      </UserDetails>
      <ButtonAsLink
        level="hollow"
        size="small"
        to={paths.logout}
        label="Déconnexion"
      />
    </RowFlex>
  )
}
