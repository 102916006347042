import { AchatStatuts } from '~/store/achats/achats.types'
import { Permissions } from '~/store/permissions/permissions.types'
import { Normalized } from '~/store/selectors'

export type CollaborateurFromApi = {
  id: string
  civilite: string
  nom: string
  prenom: string
  avatar: string
  couleur: string
  fonction: string
  biographie: string | null
  agence: string
  agences: string[]
  roles?: string[]
  statut?: string
  telephonePortable: string | null
  permissions?: Permissions[]
  email: string
}

export type Collaborateur = Normalized<
  CollaborateurFromApi & {
    rendezVous?: string[]
    achatsPolicies?: AchatPolicy[]
    visitIntentionPolicies?: VisitIntentionPolicy[]
  }
>

export type ApiCallNormalized = {
  collaborateurs?: Record<string, Collaborateur>
}

export type CollaborateurListParams = {
  withPermissions?: boolean
  ids?: string[]
}

export type CollaborateurUpdatePayload = {
  nom: string
  prenom: string
  fonction: string
  biographie: string | null
  agenceId: string
  roles: string[]
  statut: string | null
  telephonePortable: string | null
  email: string
}

export type CollaborateurAchatsFetchParams = {
  statut?: AchatStatuts
  page?: number
  perPage?: number
}

export type AchatPolicy = {
  achatId: string
  type: string
  value: boolean
}

export type VisitIntentionPolicy = {
  achatId: string
  type: VisitIntentionPolicyType
  value: boolean
  venteId: string
}

export enum VisitIntentionPolicyType {
  create = 'create',
}
