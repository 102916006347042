import { Document } from '~/store/documents/documents.types'
import { Note } from '~/store/notes/notes.types'
import { PaginationQueryParam } from '~/store/searchParams/searchParams.types'
import { ApporteurAffairesType } from '../apporteurAffaires/apporteurAffaires.types'
import { DemandeRenseignementsPayload } from '../demandesRenseignements/demandesRenseignements.types'
import {
  RendezVousDecouverteCreationPayload,
  RendezVousVisiteCreationPayload,
} from '../rendezVous/rendezVous.types'

export type AchatDetails = Partial<{
  variant: Variant | null
  bienTypes: string[] | null
  villes: string[] | null
  reference: string | null
  budgetMin: number | string | null
  budgetMax: number | string | null
  chambresCount: number | string | null
  surfaceTerrain: number | string | null
  distance: number | string | null
}>

// Si tu changes ce statut, change le côté back aussi dans Achat.php
export enum AchatStatuts {
  decouverteEnCours = 'Découverte en cours',
  visiteAPlanifier = 'Visite à planifier',
}

// Si tu changes cette constante, change le côté back aussi dans Achat.php
export enum RaisonsCloture {
  nonInteresse = "N'est plus intéressé",
  acheteAilleurs = 'A acheté ailleurs',
}

export enum RaisonsTransfert {
  changementCriteres = 'Changement de critères de recherche',
  secteurEloigne = 'Secteur de recherche trop éloigné',
  relationDifficile = 'Relation complexe avec le ou les acquéreur(s)',
}

export enum Variant {
  achatvente = 'achatvente',
  location = 'location',
}

export type TemporaryAchat = {
  id: string
  variant: Variant | null
  decouverte?: string
  collaborateur?: null | string
  contacts?: string[]
  apporteurAffairesId?: string | null
  apporteurAffairesType?: ApporteurAffairesType
}

export type ExistingAchat = {
  id: string
  variant: Variant | null
  activitesEffectuees: string[]
  clotureAt: null | string
  collaborateur: null | string
  contacts: string[]
  contreVisiteVentesToDisplay?: string[]
  createdAt: string
  decouverte: string
  demandesRenseignements: string[]
  documents: Document[]
  lastActiviteEffectueeDate?: string
  notes: Note[]
  offres?: string[]
  offreVentesToDisplay?: string[]
  raisonCloture: null | RaisonsCloture
  shareableId: string | null
  statut: AchatStatuts
  titreRecherche: string | null
  ventesIgnorees: string[]
  ventesIgnoreesToDisplay?: string[]
  ventesFavorites: string[]
  ventesFavoritesToDisplay?: string[]
  ventesSelectionnees: string[]
  ventesSelectionneesToDisplay?: string[]
  visites: string[]
  visitesIntentionAnnuleesIds?: string[]
  visiteVentesToDisplay: string[]
  mandatsRechercheIds: string[]
  apporteurAffairesId?: string | null
  apporteurAffairesType?: ApporteurAffairesType
}

export type Achat = ExistingAchat | TemporaryAchat

export type AchatCreationPayload = Omit<Achat, 'collaborateur'> &
  AchatDetails & {
    collaborateurId: string | null
    decouverteId?: string | null
  }

export type AchatWithDecouverteRendezVousCreationPayload = AchatCreationPayload &
  RendezVousDecouverteCreationPayload

export type AchatCreationWithVisiteRendezVousPayload = AchatCreationPayload &
  RendezVousVisiteCreationPayload

export type AchatCreationWithDemandeRenseignementsPayload = AchatCreationPayload &
  DemandeRenseignementsPayload

export type CloturerAchatPayload = {
  raisonCloture: RaisonsCloture | null
}

export type RendezVousContreVisiteCreationPayload = {
  dateDebut: string
  dateFin: string
  collaborateurId: string
  commentaire: string
  venteId: string
  titre: string
}

export type UpdateAchatCollaborateurPayload = {
  collaborateurId: string
}

export type TransfertAchatCollaborateurPayload = {
  collaborateurId: string
  raison: RaisonsTransfert | null
}

export type AchatsForContactsListQueryParams = PaginationQueryParam &
  AchatsContactsSearchFilters

export type AchatsContactsSearchFilters = {
  bienTypes?: string[] | null
  villes?: string[] | null
  collaborateurId?: string | null
  budgetMin?: number | null
  budgetMax?: number | null
  surfaceHabitableMin?: number | null
  surfaceHabitableMax?: number | null
  surfaceTerrainMin?: number | null
  surfaceTerrainMax?: number | null
  priorite?: 'low' | 'medium' | 'high' | null
}
