import { normalize } from 'normalizr'

import { ApiCallNormalized, CollaborateurFromApi } from './collaborateurs.types'
import { collaborateurSchema } from './collaborateurs.schema'

export const normalizeCollaborateur = (collaborateur: {
  collaborateur: CollaborateurFromApi
}): ApiCallNormalized =>
  normalize(collaborateur, {
    collaborateur: collaborateurSchema,
  }).entities as any

export const normalizeCollaborateurs = (collaborateurs: {
  collaborateurs: CollaborateurFromApi[]
}): ApiCallNormalized =>
  normalize(collaborateurs, {
    collaborateurs: [collaborateurSchema],
  }).entities as any
