import { formatDateISO, parseDateTimeBack } from '@apimmo/front/utils/dates'
import {
  append,
  contains,
  curry,
  groupBy,
  ifElse,
  map,
  reduce,
  without,
} from 'ramda'

export const unique = <T>(arrayWithDuplicates: T[]): T[] => [
  ...new Set(arrayWithDuplicates),
]

export const toggleArrayItem = (item: any) =>
  ifElse(contains(item), without([item]), append(item))

export const filterMap = curry((predicate, mapper, data) =>
  reduce(
    (acc: any[], value: any) =>
      predicate(value) ? [...acc, mapper(value)] : acc,
    [],
    data,
  ),
)

interface GroupableObject {
  createdAt: string
  id: string
}
export const groupItemsIdsByCreatedAtDate = <T extends GroupableObject>(
  array: T[],
) => {
  const arrayGroupedByDate = groupBy(
    (item) => formatDateISO(parseDateTimeBack(item.createdAt)),
    array,
  )

  return map<Record<string, T[]>, Record<string, string[]>>(
    (dates) => dates.map((item) => item.id),
    arrayGroupedByDate,
  )
}
