import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import {
  DeleteFilePayload,
  Document as DocumentType,
} from '~/store/documents/documents.types'
import { removePathAndOrDocumentFromDocumentsList } from '~/store/documents/documents.utils'
import { Entities } from '~/store/selectors'
import { unique } from '~/utils/arrays'
import { ApporteurAffairesType } from '../apporteurAffaires/apporteurAffaires.types'
import { Achat, ExistingAchat } from './achats.types'
import { isExistingAchat } from './achats.utils'

export type AchatsState = Readonly<{
  entities: Entities<Achat>
}>

const initialState: AchatsState = {
  entities: {},
}

const achatsSlice = createSlice({
  name: 'achats',
  initialState,
  reducers: {
    updateAllAchats: (
      state,
      { payload }: PayloadAction<Record<string, Achat>>,
    ) => {
      state.entities = payload
    },
    addOrUpdateAchats: (
      state,
      { payload }: PayloadAction<Record<string, Achat>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    updateAchatVisitesIntentionAnnulees: (
      state,
      {
        payload,
      }: PayloadAction<{
        visitesIntentionAnnuleesIds: string[]
        achatId: string
      }>,
    ) => {
      const achat = state.entities[payload.achatId]

      if (!achat || !isExistingAchat(achat)) return
      achat.visitesIntentionAnnuleesIds = payload.visitesIntentionAnnuleesIds
    },

    addOrUpdateDocumentToAchat: (
      state,
      { payload }: PayloadAction<{ document: DocumentType; achatId: string }>,
    ) => {
      const achat = state.entities[payload.achatId] as ExistingAchat
      const documentsFiltered = achat.documents.filter(
        ({ id }) => id !== payload.document.id,
      )
      documentsFiltered.push(payload.document)
      achat.documents = documentsFiltered
    },
    addOrUpdateApporteurAffairesToAchat: (
      state,
      {
        payload,
      }: PayloadAction<{
        apporteurAffairesId: string | null
        achatId: string
        apporteurAffairesType: ApporteurAffairesType
      }>,
    ) => {
      const achat = state.entities[payload.achatId] as Achat
      achat.apporteurAffairesId = payload.apporteurAffairesId
      achat.apporteurAffairesType = payload.apporteurAffairesType
    },
    updateAchatDocuments: (
      state,
      {
        payload,
      }: PayloadAction<{ documents: DocumentType[]; achatId: string }>,
    ) => {
      const achat = state.entities[payload.achatId] as ExistingAchat
      achat.documents = payload.documents
    },
    updateAchatContacts: (
      state,
      { payload }: PayloadAction<{ contactIds: string[]; achatId: string }>,
    ) => {
      const achat = state.entities[payload.achatId]
      if (!achat || !isExistingAchat(achat)) return
      achat.contacts = payload.contactIds
    },
    updateAchatsDecouvertes: (
      state,
      { payload }: PayloadAction<{ decouverteId: string; achatId: string }[]>,
    ) => {
      payload.forEach(({ achatId, decouverteId }) => {
        const achat = state.entities[achatId]
        if (!achat || !isExistingAchat(achat)) return
        achat.decouverte = decouverteId
      })
    },
    addOrUpdateActivitesEffectueesInAchat: (
      state,
      {
        payload,
      }: PayloadAction<{ activitesEffectueesIds: string[]; achatId: string }>,
    ) => {
      const achat = state.entities[payload.achatId]
      if (!achat || !isExistingAchat(achat)) return
      achat.activitesEffectuees = unique([
        ...(achat.activitesEffectuees || []),
        ...payload.activitesEffectueesIds,
      ])
    },
    addMandatsRechercheToAchat: (
      state,
      {
        payload,
      }: PayloadAction<{ mandatsRechercheIds: string[]; achatId: string }>,
    ) => {
      const achat = state.entities[payload.achatId]
      if (!achat || !isExistingAchat(achat)) return
      achat.mandatsRechercheIds = unique([
        ...(achat.mandatsRechercheIds || []),
        ...payload.mandatsRechercheIds,
      ])
    },
    deleteAchat: (state, { payload: achatId }: PayloadAction<string>) => {
      delete state.entities[achatId]
    },
    addOffresToAchat: (
      state,
      { payload }: PayloadAction<{ achatId: string; offres: string[] }>,
    ) => {
      const achat = state.entities[payload.achatId]
      if (!achat || !isExistingAchat(achat)) return

      achat.offres = unique([...(achat.offres || []), ...payload.offres])
    },
    deleteDocumentOfAchat(
      state,
      {
        payload,
      }: PayloadAction<DeleteFilePayload & { achatId: string; docId: string }>,
    ) {
      const { docId, path, achatId } = payload
      const achat = state.entities[achatId]
      if (!achat || !isExistingAchat(achat)) return

      achat.documents = removePathAndOrDocumentFromDocumentsList(
        achat.documents,
        docId,
        path,
      )
    },
  },

  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  updateAllAchats,
  updateAchatVisitesIntentionAnnulees,
  addOrUpdateAchats,
  addOrUpdateDocumentToAchat,
  updateAchatDocuments,
  updateAchatContacts,
  deleteAchat,
  addOffresToAchat,
  deleteDocumentOfAchat,
  addOrUpdateActivitesEffectueesInAchat,
  addOrUpdateApporteurAffairesToAchat,
  updateAchatsDecouvertes,
  addMandatsRechercheToAchat,
} = achatsSlice.actions
export const { reducer: achatsReducer } = achatsSlice
