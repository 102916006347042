import { Photo } from '@apimmo/front/utils/types'
import { indexBy, prop, sortBy } from 'ramda'
import { isNilOrEmpty } from 'ramda-adjunct'
import { Diffusion } from '~/store/diffusions/diffusions.types'
import {
  Bien,
  BienFull,
  BienWithAdresse,
  BienWithCadastre,
} from './biens.types'

type GetBienAdresseCompleteParam = {
  adressePostale: string
  codePostal: string
  ville: string
}

export const getBienAdresseComplete = ({
  adressePostale,
  codePostal,
  ville,
}: GetBienAdresseCompleteParam) =>
  `${adressePostale ? `${adressePostale  }, ` : ''}${codePostal} ${ville}`.trim()

export const hasAdresse = (bien: Bien): bien is BienWithAdresse =>
  Boolean(bien.adressePostale && bien.codePostal && bien.ville)

export const isBienFull = (bien: Bien): bien is BienFull =>
  'classeEnergetique' in bien

export const hasCadastre = (bien: Bien): bien is BienWithCadastre =>
  'referencesCadastrales' in bien

export const getBienFirstPhoto = (bien: Bien) => {
  if (bien.firstPhoto) return bien.firstPhoto.path
  return 'photos' in bien ? bien.photos[0]?.path : null
}

export const getBienPhotos = (bien: BienFull, diffusion?: Diffusion | null) => {
  const allPhotos = getAllBienPhotos(bien)
  if (!bien.diffusion || !diffusion || isNilOrEmpty(diffusion?.photos))
    return allPhotos.filter(Boolean)

  const bienPhotosById = indexBy(prop('id'), allPhotos)
  const sortedDiffusionPhotos = sortBy(prop('order'), diffusion.photos).map(
    ({ id }) => bienPhotosById[id],
  )

  return sortedDiffusionPhotos
}

export const getAllBienPhotos = (bien: BienFull) => {
  const piecesPhotos = bien.pieces
    ? bien.pieces.reduce(
        (piece: Photo[], { photos }) => [...piece, ...(photos || [])],
        [],
      )
    : []

  return bien.photos.concat(piecesPhotos)
}

const notHabitableType = ['Terrain', 'Parking', 'Cave', 'Garage']

export const getSurfaceLabelFromBienType = (bien: BienFull) =>
  notHabitableType.includes(bien.type) ? 'Surface' : 'Surface Habitable'

export const formatSurfaceCadastraleOrTerrain = (
  surfaceCadastrale: number | null,
  surfaceTerrain: number | null,
) => {
  if (surfaceCadastrale) return surfaceCadastrale

  if (surfaceTerrain) return surfaceTerrain

  return null
}
