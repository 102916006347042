import { fontName } from '@apimmo/front/style/fonts'
import { icons } from '@apimmo/front/style/icons'
import { remCalc } from '@apimmo/front/utils/selectors'

const alertLightLightness = 95
const alertDarkLightness = 40

export const colors = {
  // Primay
  primaryLighter: 'hsl(135, 40%, 98%)',
  primaryLight: 'hsl(135, 43%, 91%)',
  primary: 'hsl(135, 45%, 50%)',
  primaryDark: 'hsl(135, 45%, 40%)',

  // Secondary
  secondaryLightness: 'hsl(0, 10%, 92%)',
  secondaryLighter: 'hsl(0, 7%, 82%)',
  secondary: 'hsl(360, 5%, 29%)',
  secondaryLight: 'hsl(360, 5%, 47%)',
  secondaryDark: 'hsl(0, 5%, 16%)',

  // Grey shades
  white: 'hsl(360, 5%, 100%)',
  grey50: 'hsl(0, 0%, 96%)',
  grey100: 'hsl(0, 0%, 87%)',
  grey200: 'hsl(0, 0%, 58%)',
  grey300: 'hsl(0, 0%, 40%)',
  grey400: 'hsl(0, 0%, 22%)',

  // Alert
  error: 'hsl(360, 94%, 69%)',
  errorDark: `hsl(360, 94%, ${alertDarkLightness}%)`,
  errorLight: `hsl(360, 94%, ${alertLightLightness}%)`,
  success: 'hsl(135, 45%, 50%)',
  successDark: `hsl(135, 45%, ${alertDarkLightness}%)`,
  successLight: `hsl(135, 45%, ${alertLightLightness}%)`,
  warning: 'hsl(48, 94%, 65%)',
  warningDark: `hsl(48, 94%, ${alertDarkLightness}%)`,
  warningLight: `hsl(48, 94%, ${alertLightLightness}%)`,

  // Element colors
  new: 'hsl(11,81%,62%,1)',
} as const

export type Color = keyof typeof colors

export const textColors = {
  default: colors.grey400,
  primary: colors.primary,
  primaryDark: colors.primaryDark,
  secondary: colors.grey200,
  white: colors.white,
  error: colors.error,
  warning: colors.warningDark,
  success: colors.success,
  secondaryLight: colors.secondaryLight,
  secondaryLighter: colors.secondaryLighter,
} as const

export type TextColors = keyof typeof textColors

export const fontWeight = {
  default: 'normal',
  bold: 'bold',
  semibold: '600',
} as const

export type FontWeight = keyof typeof fontWeight

export const pastilleColors = {
  success: colors.success,
  warning: colors.warning,
  error: colors.error,
} as const

export type PastilleColors = keyof typeof pastilleColors

export const isTextColor = (color: string): color is TextColors =>
  Object.keys(textColors).includes(color)

export const defaultFontSize = 14

export const spacings = {
  null: remCalc(0),
  xxsmall: remCalc(4),
  xsmall: remCalc(8),
  small: remCalc(16),
  medium: remCalc(24),
  large: remCalc(32),
  xlarge: remCalc(40),
  xxlarge: remCalc(48),
} as const

export type Spacing = keyof typeof spacings

export const isSpacing = (spacing: string): spacing is Spacing =>
  Object.keys(spacings).includes(spacing)

export const breakpoints = {
  small: ' 0px',
  medium: '768px',
  large: '1024px',
  xlarge: '1200px',
  xxlarge: '1500px',
} as const

export const mediaQueries = {
  smallOnly: `@media only screen and (max-width : ${breakpoints.medium})`,
  medium: `@media only screen and (min-width: ${breakpoints.medium})`,
  mediumOnly: `@media only screen and (max-width : ${breakpoints.large}) and (min-width:${breakpoints.medium})`,
  mediumDown: `@media only screen and (max-width : ${breakpoints.large})`,
  large: `@media only screen and (min-width: ${breakpoints.large})`,
  largeDown: `@media only screen and (max-width : ${breakpoints.xlarge})`,
  xlarge: `@media only screen and (min-width: ${breakpoints.xlarge})`,
  xlargeDown: `@media only screen and (max-width : ${breakpoints.xxlarge})`,
  xlargeOnly: `@media only screen and (max-width : ${breakpoints.xxlarge}) and (min-width:${breakpoints.xlarge})`,
  xxlarge: `@media only screen and (min-width: ${breakpoints.xxlarge})`,
} as const

export const transition = {
  prop: 'all',
  duration: '300ms',
  timing: 'cubic-bezier(.165, .84, .44, 1)',
  global: 'all 300ms cubic-bezier(.165, .84, .44, 1)',
} as const

export const textSize = {
  default: remCalc(defaultFontSize),
  tiny: remCalc(10),
  small: remCalc(12),
  medium: remCalc(16),
  large: remCalc(18),
  xlarge: remCalc(20),
  xxlarge: remCalc(24),
} as const

export const lineHeight = {
  default: 1.7,
  tiny: 1,
  small: 1.3,
  medium: 1.5,
} as const

export const borderRadius = '6px'

export const fontFamily = `"${fontName}", Arial, Helvetica, sans-serif`

export type Icons = keyof typeof icons

export const maxWidth = remCalc(1200)

export const modalWidth = {
  small: remCalc(600),
  default: remCalc(900),
  large: remCalc(1200),
}

export const zIndex = {
  element: 1,
  dropdown: 10,
  modal: 20,
  notification: 30,
}

export const statutColors = {
  primary: colors.primary,
  error: colors.error,
  success: colors.success,
  warning: colors.warning,
  grey: colors.grey300,
  secondary: colors.secondaryLighter,
  transparent: 'transparent',
  new: colors.new,
  sold: colors.warning,
}

export type StatutColors = keyof typeof statutColors
