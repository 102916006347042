import { withPluralSuffix } from '@apimmo/front/utils/strings'
import {
  differenceInDays,
  format as baseFormat,
  getHours,
  getMinutes,
  parse as baseParse,
  set,
} from 'date-fns'
import fr from 'date-fns/locale/fr'
import { compose, curry, pipe } from 'ramda'
import { useMemo } from 'react'

export const format = curry((formatTemplate: string, date: Date) =>
  baseFormat(date, formatTemplate, { locale: fr }),
)

export const parse = curry((parseTemplate: string, date: string) =>
  baseParse(date, parseTemplate, new Date()),
)

export const getHoursFromDate = format('HH:mm')

export const formatLong = format('PPPP')
export const formatDateISO = format('yyyy-MM-dd')
export const formatDateStringISO = (date: string) =>
  date && format('yyyy-MM-dd', new Date(date))
export const formatDateStringFR = (date: string) =>
  date && format('dd/MM/yyyy', new Date(date))
export const formatDateFr = format('dd/MM/yyyy')
export const formatDateTimeFr = format('dd/MM/yyyy à HH:mm')
export const formatDateLong = format('dd MMMM yyyy')

export const parseDateTimeBack = parse('yyyy-MM-dd HH:mm:ss')
export const formatDateTimeBack = format('yyyy-MM-dd HH:mm:ss')
export const formatTime = parse('HH:mm')

export const displayDateFr = (value: Date | string) =>
  formatDateFr(parseDateTimeBack(value.toString()))
export const displayDateTimeFr = (value: Date | string) =>
  formatDateTimeFr(parseDateTimeBack(value.toString()))
export const displayHours = (value: Date | string) =>
  getHoursFromDate(parseDateTimeBack(value.toString()))

export const displayDateWithHours = (date: Date | string) => `
  ${format('iii d MMM yyyy', new Date(date))} à ${format(
  'HH:mm',
  new Date(date),
)}
`

export const setTimeToDate = (date: string, time: string) => {
  const hours = pipe(formatTime, getHours, Number)(time)
  const minutes = pipe(formatTime, getMinutes, Number)(time)
  return new Date(set(new Date(date), { hours, minutes }))
}

export const dateFormater = (date: Date, time: string) =>
  `${formatDateISO(date)} ${time}:00`
export const emptyIfMidnight = (hour: string) => (hour !== '00:00' ? hour : '')
export const getHoursFromDateOrEmpty = compose<Date, string, string>(
  emptyIfMidnight,
  getHoursFromDate,
)

export const displayDayDifferenceFromToday = (dateISO: string) => {
  const parsedDate = parse('yyyy-MM-dd', dateISO)
  const diffInDays = differenceInDays(new Date(), parsedDate)
  if (diffInDays === 0) {
    return "Aujourd'hui"
  }
  if (diffInDays === 1) {
    return 'Hier'
  }
  return withPluralSuffix(diffInDays, `Il y a ${diffInDays} jour`)
}

export const useSortDatesFromObjectWithDateKeys = (
  objectWithDateKeys: Record<string, string[]>,
) =>
  useMemo(
    () =>
      Object.keys(objectWithDateKeys).sort(
        (a, b) => new Date(b).valueOf() - new Date(a).valueOf(),
      ),
    [objectWithDateKeys],
  )

export const formatDateFrom = (date?: string | null) =>
  date ? `de ${formatDateStringFR(date)}` : ''

export const formatDateTo = (date?: string | null) =>
  date ? `à ${formatDateStringFR(date)}` : ''
