import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { MandatsRechercheEntities } from './mandatsRecherche.types'

export type MandatsRechercheState = Readonly<{
  entities: MandatsRechercheEntities
}>

const initialState: MandatsRechercheState = {
  entities: {},
}

const mandatsRechercheSlice = createSlice({
  name: 'mandatsRecherche',
  initialState,
  reducers: {
    addOrUpdateMandatsRecherche: (
      state,
      { payload }: PayloadAction<MandatsRechercheEntities>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const { addOrUpdateMandatsRecherche } = mandatsRechercheSlice.actions
export const { reducer: mandatsRechercheReducer } = mandatsRechercheSlice
