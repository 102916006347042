import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'

interface AxiosExtra extends AxiosInstance {
  setHeader: (
    name: string,
    value: any,
    scopes: string | string[] | undefined,
  ) => void
  setToken: (
    token: any,
    type: string,
    scopes: string | string[] | undefined,
  ) => void
  onRequest: (fn: (config: AxiosRequestConfig) => AxiosRequestConfig) => void
  onResponse: (fn: (response: AxiosResponse) => AxiosResponse) => void
  onRequestError: (fn: (error: AxiosError) => any) => void
  onResponseError: (fn: (error: AxiosError) => any) => void
  onError: (fn: (error: AxiosError) => any) => void

  $request<T = any>(config: AxiosRequestConfig): Promise<T>

  $get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>

  $delete(url: string, config?: AxiosRequestConfig): Promise<void>

  $head(url: string, config?: AxiosRequestConfig): Promise<void>

  $post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T>

  $put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T>

  $patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T>
}

// @ts-ignore
const axiosExtra: AxiosExtra = {
    setHeader(name, value, scopes = 'common') {
      ;(Array.isArray(scopes) ? scopes : [scopes]).forEach((scope) => {
        if (!value) {
          delete this.defaults.headers[scope][name]
          return
        }
        this.defaults.headers[scope][name] = value
      })
    },
    setToken(token, type, scopes = 'common') {
      const value = !token ? null : (type ? `${type} ` : '') + token
      this.setHeader('Authorization', value, scopes)
    },
    onRequest(fn) {
      this.interceptors.request.use(fn)
    },
    onResponse(fn) {
      this.interceptors.response.use(fn)
    },
    onRequestError(fn) {
      this.interceptors.request.use(undefined, fn)
    },
    onResponseError(fn) {
      this.interceptors.response.use(undefined, fn)
    },
    onError(fn) {
      this.onRequestError(fn)
      this.onResponseError(fn)
    },
  }

  // Request helpers ($get, $post, ...)
;['request', 'delete', 'get', 'head', 'post', 'put', 'patch'].forEach(
  (method) => {
    // @ts-ignore
    axiosExtra[`$${method}`] = function axiosExtraMethod(...args) {
      // @ts-ignore
      return this[method](...args).then((res: AxiosResponse) => res && res.data)
    }
  },
)

const extendAxiosInstance = (axios: AxiosInstance) => {
  Object.keys(axiosExtra).forEach(
    // @ts-ignore
    // eslint-disable-next-line
    (key) => (axios[key] = axiosExtra[key].bind(axios)),
  )
}

export const httpFactory = (options?: AxiosRequestConfig): AxiosExtra => {
  const axios = Axios.create(options)

  extendAxiosInstance(axios)

  return axios as AxiosExtra
}
