import { Link, LinkProps } from 'react-router-dom'
import { withProps } from 'recompose'
import { FC } from '~/utils/types'
import { Button, ButtonProps } from '../Buttons/Button'

type ButtonAsLinkType = FC<ButtonProps & LinkProps & { as: typeof Link }>
export const ButtonAsLink = withProps({ as: Link })(Button as ButtonAsLinkType)

type ButtonAsExternalLinkType = FC<
  ButtonProps & JSX.IntrinsicElements['a'] & { as: 'a' }
>
export const ButtonAsExternalLink = withProps({ as: 'a' })(
  Button as ButtonAsExternalLinkType,
)
