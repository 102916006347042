import { Spacing } from '@apimmo/front/style/theme'
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { computeGap, getFrValue } from './Grid.utils'

type GridAlignment = 'end' | 'start' | 'center' | 'space-between'
type ItemAlignment = 'end' | 'start' | 'center'
type Gap = string | Spacing

type GridCellProps = {
  area?: string
  column?: number | string
  columnsSizing?: string | number
  direction?: 'column' | 'row'
  gap?: Gap | Gap[]
  height?: number
  horizontalAlignment?: GridAlignment
  horizontalItemAlignment?: ItemAlignment
  order?: number
  row?: number | string
  rowsSizing?: string | number
  selfHorizontalAlignment?: ItemAlignment
  selfVerticalAlignment?: ItemAlignment
  verticalAlignment?: ItemAlignment
  verticalContentAlignment?: GridAlignment
  width?: number
}

export const GridCell = styled('div', {
  shouldForwardProp: isPropValid,
})<GridCellProps>`
  display: inline-grid;
  ${({ columnsSizing }) =>
    columnsSizing && `grid-template-columns: ${getFrValue(columnsSizing)}`};
  ${({ rowsSizing }) =>
    rowsSizing && `grid-template-rows: ${getFrValue(rowsSizing)}`};
  grid-gap: ${({ gap }) => gap && computeGap(gap)};
  grid-column-end: ${({ width = 1 }) => `span ${width}`};
  grid-row-end: ${({ height = 1 }) => `span ${height}`};
  ${({ column }) => column && `grid-column: ${column}`};
  ${({ row }) => row && `grid-row: ${row}`};
  ${({ area }) => area && `grid-area: ${area}`};
  ${({ horizontalAlignment }) =>
    horizontalAlignment &&
    `
    justify-content: ${horizontalAlignment};
  `};
  ${({ horizontalItemAlignment }) =>
    horizontalItemAlignment &&
    `
    justify-items: ${horizontalItemAlignment}
  `};
  ${({ verticalAlignment }) =>
    verticalAlignment &&
    `
    align-items: ${verticalAlignment};
  `};
  ${({ verticalContentAlignment }) =>
    verticalContentAlignment &&
    `
    align-content: ${verticalContentAlignment};
  `};
  ${({ selfHorizontalAlignment }) =>
    selfHorizontalAlignment &&
    `
    justify-self: ${selfHorizontalAlignment};
  `};
  ${({ selfVerticalAlignment }) =>
    selfVerticalAlignment &&
    `
    align-self: ${selfVerticalAlignment};
  `};
  ${({ direction }) => direction && `grid-auto-flow: ${direction}`};
  ${({ order }) => order && `order: ${order}`};
`
