import { curry, pluck, prop, sortBy } from 'ramda'
import { compact } from 'ramda-adjunct'
import { RootState } from '~/store/types'
import { Secteur } from './secteurs.types'
import { formatSecteursAsOption } from './secteurs.utils'

export const selectSortedSecteurs = (state: RootState): Secteur[] =>
  sortBy(prop('ville'), compact(Object.values(state.secteurs.entities)))

export const selectSecteurById = curry(
  (secteurId: string, state: RootState) => state.secteurs.entities[secteurId],
)

export const selectSecteursListByIds = curry(
  (secteursIds: string[], state: RootState) => {
    if (secteursIds.length === 0) return []

    return compact(secteursIds.map((id) => selectSecteurById(id, state)))
  },
)

export const selectSecteursCollaborateurLocation = (state: RootState) => {
  const secteursCollaborateursLocation = (
    state.secteurs.secteursCollaborateurLocationIds || []
  )
    .map((id) => selectSecteurById(id, state))
    .filter((secteur) => Boolean(secteur))
  return secteursCollaborateursLocation as Secteur[]
}

export const selectSecteursCollaborateurLocationOptions = (state: RootState) =>
  formatSecteursAsOption(selectSecteursCollaborateurLocation(state))

export const selectSecteursCollaborateurAchatVente = (state: RootState) => {
  const secteursCollaborateursAchatVente = (
    state.secteurs.secteursCollaborateurAchatVenteIds || []
  )
    .map((id) => selectSecteurById(id, state))
    .filter((secteur) => Boolean(secteur))
  return secteursCollaborateursAchatVente as Secteur[]
}

export const selectSecteursCollaborateurAchatVenteVilles = (
  state: RootState,
) => {
  const secteurs = selectSecteursCollaborateurAchatVente(state)
  return pluck('ville', secteurs).sort()
}

export const selectSecteursAchatVenteVillesByCollaborateurId = curry(
  (collaborateurId: string, state: RootState) => {
    const secteurs = state.secteurs.entities

    const collaborateurSecteurs = (secteurs &&
    Object.values(secteurs).length > 0
      ? Object.values(secteurs).filter(
          (secteur) =>
            secteur && secteur.collaborateurAchatVenteId === collaborateurId,
        )
      : []) as Secteur[]
    return pluck('ville', collaborateurSecteurs).sort()
  },
)
