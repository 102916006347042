import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Entities } from '~/store/selectors'
import { CompromisDisponibilite } from './compromisDisponibilites.types'

export type CompromisDisponibilitesState = Readonly<{
  entities: Entities<CompromisDisponibilite>
}>

const initialState: CompromisDisponibilitesState = {
  entities: {},
}

const compromisDisponibilitesSlice = createSlice({
  name: 'compromisDisponibilites',
  initialState,
  reducers: {
    addOrUpdateCompromisDisponibilites: (
      state,
      { payload }: PayloadAction<Record<string, CompromisDisponibilite>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  addOrUpdateCompromisDisponibilites,
} = compromisDisponibilitesSlice.actions
export const {
  reducer: compromisDisponibilitesReducer,
} = compromisDisponibilitesSlice
