import { spacings } from '@apimmo/front/style/theme'
import styled from '@emotion/styled'
import React from 'react'
import { colors } from '~/style/theme'
import { AppUserMenu } from './AppUserMenu'

const TopBarWrapper = styled.div`
  grid-area: topbar;
  padding: ${spacings.small} ${spacings.medium};
  background: ${colors.white};
  border-bottom: 1px solid ${colors.grey100};
`

export const TopBar = () => (
  <TopBarWrapper>
    <AppUserMenu />
  </TopBarWrapper>
)
