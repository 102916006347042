import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { VisitesEntities } from './visites.types'

export type VisitesState = Readonly<{
  entities: VisitesEntities
}>

const initialState: VisitesState = {
  entities: {},
}

const visitesSlice = createSlice({
  name: 'visites',
  initialState,
  reducers: {
    updateAllVisites: (state, { payload }: PayloadAction<VisitesEntities>) => {
      state.entities = payload
    },
    addOrUpdateVisites: (
      state,
      { payload }: PayloadAction<VisitesEntities>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const { updateAllVisites, addOrUpdateVisites } = visitesSlice.actions
export const { reducer: visitesReducer } = visitesSlice
