import { Photo } from '@apimmo/front/utils/types'
import { findIndex, includes, pipe, prop, propEq, reject } from 'ramda'
import { isNilOrEmpty } from 'ramda-adjunct'
import { Document } from '~/store/documents/documents.types'

export const getNbDocuments = (documents: Document[]) =>
  documents.filter(({ paths }) => !isNilOrEmpty(paths)).length

const contactDocuments = {
  id: "Carte d'identité",
  livret: 'Livret de famille',
  contract: 'Contrat de mariage/pacs',
  survey: "Questionnaire d'état civil",
} as const

type ContactDocument = keyof typeof contactDocuments

export const getIconNameByLabel = (label: string) =>
  (Object.keys(contactDocuments) as ContactDocument[]).find(
    (name) => contactDocuments[name] === label,
  ) || 'document'

export const computeRemainingPaths = (
  paths: string[] | null,
  pathToFilter: string,
) => {
  if (!paths) return paths
  const filteredPaths = paths.filter(
    (currentPath) => currentPath !== pathToFilter,
  )
  return filteredPaths
}

export const removePathAndOrDocumentFromDocumentsList = (
  documentsList: Document[],
  docId: string,
  pathToRemove: string,
) => {
  const documentIndex = findIndex(propEq('id', docId), documentsList)
  if (documentIndex == null || !documentsList[documentIndex]) {
    return documentsList
  }
  const remainingPaths = computeRemainingPaths(
    documentsList[documentIndex].paths,
    pathToRemove,
  )
  if (remainingPaths && remainingPaths.length > 0) {
    documentsList[documentIndex].paths = remainingPaths
    return documentsList
  }
  return documentsList.filter(({ id }) => id !== docId)
}

export const removePhotosFromList = (
  photos: Photo[],
  idsToRemove: string[],
) => {
  const isInRemoveList = (id: string) => includes(id, idsToRemove)
  const getId = (photo: Photo) => prop('id', photo)
  const hasToBeRemoved = pipe(getId, isInRemoveList)

  return reject(hasToBeRemoved, photos)
}
