import { format as baseFormat, parse as baseParse } from 'date-fns'
import fr from 'date-fns/locale/fr'
import { curry } from 'ramda'

export const format = curry((formatTemplate: string, date: Date) =>
  baseFormat(date, formatTemplate, { locale: fr }),
)

export const parse = curry((parseTemplate: string, date: string) =>
  baseParse(date, parseTemplate, new Date()),
)

export const getHoursFromDate = format('HH:mm')

export const formatLong = format('PPPP')
export const formatDateISO = format('yyyy-MM-dd')
export const formatDateFr = format('dd/MM/yyyy')
export const formatDateLong = format('dd MMMM yyyy')

export const parseDateTimeBack = parse('yyyy-MM-dd HH:mm:ss')
export const formatDateTimeBack = format('yyyy-MM-dd HH:mm:ss')

export const displayDateFr = (value: string) =>
  formatDateFr(parseDateTimeBack(value.toString()))
export const displayDateLongFr = (value: string) =>
  formatDateLong(parseDateTimeBack(value.toString()))
export const displayHours = (value: string) =>
  getHoursFromDate(parseDateTimeBack(value.toString()))

export const displayDateWithHours = (date: Date | string) => `
  ${format('iii d MMM yyyy', new Date(date))} à ${format(
  'HH:mm',
  new Date(date),
)}
`
