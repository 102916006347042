import { normalize } from 'normalizr'
import { Bien } from '~/store/biens/biens.types'
import { Collaborateur } from '~/store/collaborateurs/collaborateurs.types'
import { Notaire } from '~/store/notaires/notaires.types'
import { RendezVous } from '~/store/rendezVous/rendezVous.types'
import { Vente } from '~/store/ventes/ventes.types'
import { contactSchema, contactsSchema } from './contacts.schema'
import { Contact } from './contacts.types'

type NormalizedContact = {
  contacts?: Record<string, Contact>
  ventes?: Record<string, Vente>
  biens?: Record<string, Bien>
  collaborateurs?: Record<string, Collaborateur>
  rendezVous?: Record<string, RendezVous>
  notaires?: Record<string, Notaire>
}

export const normalizeContact = (contact: Contact): NormalizedContact =>
  normalize(contact, contactSchema).entities as any

export const normalizeContacts = (contacts: Contact[]): NormalizedContact =>
  normalize(contacts, [contactsSchema]).entities as any
