import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { Entities } from '~/store/selectors'
import { Tache } from './taches.type'

export type TachesState = Readonly<{
  entities: Entities<Tache>
}>

const initialState: TachesState = {
  entities: {},
}

const tachesSlice = createSlice({
  name: 'taches',
  initialState,
  reducers: {
    addOrUpdateTaches: (
      state,
      { payload }: PayloadAction<Record<string, Tache>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
})
export const { addOrUpdateTaches } = tachesSlice.actions

export const { reducer: tachesReducer } = tachesSlice
