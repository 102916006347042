import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Entities } from '~/store/selectors'
import { Diffusion } from './diffusions.types'

export type DiffusionsState = Readonly<{
  entities: Entities<Diffusion>
}>

const initialState: DiffusionsState = {
  entities: {},
}

const diffusionsSlice = createSlice({
  name: 'diffusions',
  initialState,
  reducers: {
    addOrUpdateDiffusion: (
      state,
      { payload }: PayloadAction<Record<string, Diffusion>>,
    ) => {
      state.entities = payload
    },
  },
})
export const { addOrUpdateDiffusion } = diffusionsSlice.actions

export const { reducer: diffusionsReducer } = diffusionsSlice
