import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import {
  DeleteFilePayload,
  Document as DocumentType,
} from '~/store/documents/documents.types'
import { removePathAndOrDocumentFromDocumentsList } from '~/store/documents/documents.utils'
import { unique } from '~/utils/arrays'
import { VentesEntities } from './ventes.types'

export type VentesState = Readonly<{
  entities: VentesEntities
}>

const initialState: VentesState = {
  entities: {},
}

const ventesSlice = createSlice({
  name: 'ventes',
  initialState,
  reducers: {
    updateAllVentes: (state, { payload }: PayloadAction<VentesEntities>) => {
      state.entities = payload
    },
    addOrUpdateVentes: (state, { payload }: PayloadAction<VentesEntities>) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    addMandatsToVente(
      state,
      { payload }: PayloadAction<{ venteId: string; mandatsIds: string[] }>,
    ) {
      const { venteId, mandatsIds } = payload
      const vente = state.entities[venteId]

      if (!vente) return

      vente.mandatsIds = unique([...(vente.mandatsIds || []), ...mandatsIds])
    },
    deleteVente: (state, { payload: venteId }: PayloadAction<string>) => {
      delete state.entities[venteId]
    },
    addOffresToVente: (
      state,
      { payload }: PayloadAction<{ venteId: string; offres: string[] }>,
    ) => {
      const vente = state.entities[payload.venteId]
      if (!vente) return

      vente.offres = payload.offres
    },
    updateVenteDocuments: (
      state,
      {
        payload,
      }: PayloadAction<{ documents: DocumentType[]; venteId: string }>,
    ) => {
      const vente = state.entities[payload.venteId]
      if (!vente) return
      vente.documents = payload.documents
    },
    addOrUpdateDocumentToVente: (
      state,
      { payload }: PayloadAction<{ document: DocumentType; venteId: string }>,
    ) => {
      const vente = state.entities[payload.venteId]
      if (!vente) return

      const documentsFiltered = vente.documents
        ? vente.documents.filter(({ id }) => id !== payload.document.id)
        : []
      documentsFiltered.push(payload.document)
      vente.documents = documentsFiltered
    },
    deleteDocumentOfVente(
      state,
      {
        payload,
      }: PayloadAction<DeleteFilePayload & { venteId: string; docId: string }>,
    ) {
      const { docId, path, venteId } = payload
      const vente = state.entities[venteId]
      if (!vente || !vente.documents) return
      const docs = removePathAndOrDocumentFromDocumentsList(
        vente.documents,
        docId,
        path,
      )
      vente.documents = docs
    },
  },

  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  updateAllVentes,
  addOrUpdateVentes,
  addMandatsToVente,
  deleteVente,
  addOffresToVente,
  updateVenteDocuments,
  addOrUpdateDocumentToVente,
  deleteDocumentOfVente,
} = ventesSlice.actions
export const { reducer: ventesReducer } = ventesSlice
