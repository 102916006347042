import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Entities } from '~/store/selectors'
import { Decouverte } from './decouvertes.types'

export type DecouvertesState = Readonly<{
  entities: Entities<Decouverte>
}>

const initialState: DecouvertesState = {
  entities: {},
}

const decouvertesSlice = createSlice({
  name: 'decouvertes',
  initialState,
  reducers: {
    addOrUpdateDecouvertes: (
      state,
      { payload }: PayloadAction<Record<string, Decouverte>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const { addOrUpdateDecouvertes } = decouvertesSlice.actions
export const { reducer: decouvertesReducer } = decouvertesSlice
