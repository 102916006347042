export const paths = {
  // achat pages
  newAchat: '/contacts/:contactId/achats/new/:redirectTo/:variant?',
  achatPages: '/contacts/:contactId/achats/:achatId',
  decouverte: '/contacts/:contactId/achats/:achatId/decouverte/:step',
  visiteListVentes: '/contacts/:contactId/achats/:achatId/ventes/:state?',

  // achat tabs
  projetAchat: '/contacts/:contactId/achats/:achatId/projet',
  projetAchatDecouverte:
    '/contacts/:contactId/achats/:achatId/projet/decouverte',
  projetAchatDocuments: '/contacts/:contactId/achats/:achatId/projet/documents',
  projetAchatNotes: '/contacts/:contactId/achats/:achatId/projet/notes',
  projetAchatParticipants:
    '/contacts/:contactId/achats/:achatId/projet/acquereurs',
  projetAchatOffres: '/contacts/:contactId/achats/:achatId/projet/offres',
  projetAchatAdministration:
    '/contacts/:contactId/achats/:achatId/projet/administration',

  // clients list page
  collaborateurVendeurs: '/collaborateur/clients/vendeurs',
  collaborateurBailleurs: '/collaborateur/clients/bailleurs',
  collaborateurClients: '/collaborateur/clients',
  collaborateurAcquereurs: '/collaborateur/clients/acquereurs',
  collaborateurLocataires: '/collaborateur/clients/locataires',
  clientsSupprimes: '/collaborateur/clients/supprimes',

  // visite pages
  visitePages: '/contacts/:contactId/achats/:achatId/ventes/:venteId',
  visiteVenteDetails:
    '/contacts/:contactId/achats/:achatId/ventes/:venteId/details',
  visitePlanification:
    '/contacts/:contactId/achats/:achatId/ventes/:venteId/visite',
  demandeRenseignements:
    '/contacts/:contactId/achats/:achatId/ventes/:venteId/renseignement',
  renseignementRecapitulatif:
    '/contacts/:contactId/achats/:achatId/ventes/:venteId/demande-renseignement/:demandeRenseignementsId/recapitulatif',
  visiteRecapitulatif:
    '/contacts/:contactId/achats/:achatId/ventes/:venteId/rendezVous/:rendezVousId/recapitulatif',

  // compromis pages
  compromis: '/offres/:offreId/compromis',
  compromisDashboard: '/offres/:offreId/compromis/dashboard',
  compromisMatrice: '/offres/:offreId/compromis/dashboard/matrice',
  compromisDocuments: '/offres/:offreId/compromis/dashboard/documents',
  compromisConditionsSuspensives:
    '/offres/:offreId/compromis/dashboard/conditions-suspensives',
  compromisRendezVous: '/offres/:offreId/compromis/dashboard/rendezvous',
  venteRendezVous: '/offres/:offreId/compromis/dashboard/rendezvous-vente',
  participants: '/offres/:offreId/compromis/dashboard/participants',

  // contact pages
  findContactByPhone: '/contacts',
  editContact: '/contacts/:contactId/edit',
  showContact: '/contacts/:contactId',
  contactTransactions: '/contacts/:contactId',
  planificationDecouverteRendezVous:
    '/contacts/:contactId/achats/:achatId/planification-decouverte-rendezVous/:step/:rendezVousId?',

  // vente pages
  newVente: '/contacts/:contactId/ventes/new/:variant?',
  estimationPlanification: '/contacts/:contactId/ventes/:venteId/planifier',
  estimationRecapitulatif:
    '/contacts/:contactId/ventes/:venteId/rendezVous/:rendezVousId/recapitulatif',
  projetVente: '/contacts/:contactId/ventes/:venteId/projet',
  ventePages: '/contacts/:contactId/ventes/:venteId',

  // vente tabs
  projetVenteDetails: '/contacts/:contactId/ventes/:venteId/projet/details',
  projetVenteEstimation:
    '/contacts/:contactId/ventes/:venteId/projet/estimation',
  projetVentePublication:
    '/contacts/:contactId/ventes/:venteId/projet/publication',
  projetVenteDocuments: '/contacts/:contactId/ventes/:venteId/projet/documents',
  projetVenteOffres: '/contacts/:contactId/ventes/:venteId/projet/offres',
  projetVenteParticipants:
    '/contacts/:contactId/ventes/:venteId/projet/participants',
  projetVenteNotes: '/contacts/:contactId/ventes/:venteId/projet/notes',
  projetVenteAdministration:
    '/contacts/:contactId/ventes/:venteId/projet/administration',

  // main pages
  home: '/',
  login: '/login',
  logout: '/logout',
  collaborateurAgenda: '/collaborateur/agenda',
  tasks: '/taches',
  rapprochements: '/rapprochements',

  // admin pages
  secteurs: '/secteurs',
  collaborateurs: '/collaborateurs',
  vacances: '/vacances',
  agences: '/agences',
} as const
