import { curry } from 'ramda'
import { compact } from 'ramda-adjunct'
import { RootState } from '~/store/types'
import { groupItemsIdsByCreatedAtDate } from '~/utils/arrays'

export const selectRapprochementById = curry(
  (rapprochementId: string, state: RootState) =>
    state.rapprochements.entities[rapprochementId],
)

export const selectRapprochementsIdsGroupedByDate = curry(
  (rapprochementsIds: string[], state: RootState) => {
    const rapprochements = compact(
      rapprochementsIds.map((rapprochementId) =>
        selectRapprochementById(rapprochementId, state),
      ),
    )

    return groupItemsIdsByCreatedAtDate(rapprochements)
  },
)
