export const isProd = process.env.NODE_ENV === 'production'

export const baseApiUrl = process.env.API_URL!.replace(/\/$/, '')

export const apiUrl = (path = '') => {
  const trimmedPath = path.replace(/\/$/, '').replace(/^\//, '')

  return `${baseApiUrl}/${trimmedPath}`
}

export const apiNumberFactor = 100

export const sentryDSN = process.env.SENTRY_DSN
