import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Entities } from '~/store/selectors'
import { OffreProposition } from './offrePropositions.types'

export type OffrePropositionsState = Readonly<{
  entities: Entities<OffreProposition>
}>

const initialState: OffrePropositionsState = {
  entities: {},
}

const offrePropositionsSlice = createSlice({
  name: 'offrePropositions',
  initialState,
  reducers: {
    addOrUpdateOffrePropositions: (
      state,
      { payload }: PayloadAction<Record<string, OffreProposition>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const { addOrUpdateOffrePropositions } = offrePropositionsSlice.actions
export const { reducer: offrePropositionsReducer } = offrePropositionsSlice
