import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useBoolean, useMount } from 'react-use'
import { addOrUpdateAchats } from '~/store/achats/achats.slice'
import { updateCurrentUserId } from '~/store/auth/auth.slice'
import {
  addOrUpdateCollaborateurs,
  addOrUpdateCollaborateursAchatsVente,
  addOrUpdateCollaborateursLocation,
  removeCollaborateur,
} from '~/store/collaborateurs/collaborateurs.slice'
import {
  CollaborateurAchatsFetchParams,
  CollaborateurListParams,
  CollaborateurUpdatePayload,
} from '~/store/collaborateurs/collaborateurs.types'
import { updateContactPatch } from '~/store/contacts/contacts.api'
import { addOrUpdateContacts } from '~/store/contacts/contacts.slice'
import { selectOffreById } from '~/store/offres/offres.selectors'
import { useSelector } from '~/store/utils'
import { selectVenteById } from '~/store/ventes/ventes.selectors'
import { getFirst } from '~/utils/get'
import { useAppToasts, useAsync, useAsyncFn } from '~/utils/hooks'
import {
  deleteCollaborateur,
  fetchCollaborateur,
  fetchCollaborateurAchats,
  fetchCollaborateursAchatVenteList,
  fetchCollaborateursList,
  fetchCollaborateursLocationList,
  fetchConnectedCollaborateur,
  updateCollaborateur,
} from './collaborateurs.api'
import {
  selectCollaborateurById,
  selectCollaborateurByVenteId,
} from './collaborateurs.selectors'

export const useFetchCollaborateurs = () => {
  const dispatch = useDispatch()
  const [, doCall] = useAsyncFn(
    async (params: CollaborateurListParams = {}) => {
      const { collaborateurs = {} } = await fetchCollaborateursList(params)

      dispatch(addOrUpdateCollaborateurs(collaborateurs))
    },
    [],
  )

  return doCall
}

export const useFetchAllCollaborateursOnMount = (
  params: CollaborateurListParams = {},
) => {
  const fetchAllCollaborateurs = useFetchCollaborateurs()
  useMount(() => fetchAllCollaborateurs(params))

  return fetchAllCollaborateurs
}

export const useFetchCollaborateursAchatVente = () => {
  const dispatch = useDispatch()
  return useAsync(async () => {
    const { collaborateurs = {} } = await fetchCollaborateursAchatVenteList()
    dispatch(addOrUpdateCollaborateurs(collaborateurs))
    dispatch(addOrUpdateCollaborateursAchatsVente(Object.keys(collaborateurs)))
  }, [])
}

export const useFetchCollaborateursLocation = () => {
  const dispatch = useDispatch()
  return useAsync(async () => {
    const { collaborateurs = {} } = await fetchCollaborateursLocationList()

    dispatch(addOrUpdateCollaborateurs(collaborateurs))
    dispatch(addOrUpdateCollaborateursLocation(Object.keys(collaborateurs)))
  }, [])
}

export const useFetchConnectedCollaborateur = () => {
  const dispatch = useDispatch()
  return useAsync(async () => {
    const { collaborateurs = {} } = await fetchConnectedCollaborateur()

    dispatch(updateCurrentUserId({ userId: getFirst(collaborateurs).id }))
    dispatch(addOrUpdateCollaborateurs(collaborateurs))
  }, [])
}

export const useUpdateCollaborateur = (collaborateurId: string) => {
  const { successToast } = useAppToasts()
  const dispatch = useDispatch()

  const [, doCall] = useAsyncFn(
    async (payload: CollaborateurUpdatePayload) => {
      const { collaborateurs = {} } = await updateCollaborateur(
        collaborateurId,
        payload,
      )

      dispatch(addOrUpdateCollaborateurs(collaborateurs))

      successToast('Le collaborateur a été modifié avec succès.')
    },
    [collaborateurId],
  )

  return doCall
}

export const useCanUpdateProfile = () => {
  const [isAllowed, setIsAllowed] = useBoolean(false)
  const { contactId } = useParams<{ contactId: string }>()

  useEffect(() => {
    const check = async () => {
      try {
        await updateContactPatch(contactId, {})
        setIsAllowed(true)
      } catch {
        setIsAllowed(false)
      }
    }

    check()
  }, [contactId, setIsAllowed])

  return isAllowed
}

export const useFetchCollaborateurAchats = (collaborateurId: string) => {
  const dispatch = useDispatch()

  return useAsyncFn(async (params: CollaborateurAchatsFetchParams) => {
    const {
      data: { achats = {}, contacts = {} },
      meta,
    } = await fetchCollaborateurAchats(collaborateurId, params)
    dispatch(addOrUpdateAchats(achats))
    dispatch(addOrUpdateContacts(contacts))
    const achatsDecouverteAFaireIds = Object.keys(achats)
    return { achatsDecouverteAFaireIds, meta }
  }, [])
}

export const useSelectCollaborateurFromVenteParams = () => {
  const { venteId } = useParams<{ venteId: string }>()
  return useSelector(selectCollaborateurByVenteId(venteId))
}

export const useFetchCollaborateur = () => {
  const dispatch = useDispatch()

  const [, doCall] = useAsyncFn(async (collaborateurId: string) => {
    const { collaborateurs = {} } = await fetchCollaborateur(collaborateurId)

    dispatch(addOrUpdateCollaborateurs(collaborateurs))
  }, [])

  return doCall
}

export const useSelectOrFetchCollaborateur = (collaborateurId: string) => {
  const fetch = useFetchCollaborateur()
  const collaborateur = useSelector(selectCollaborateurById(collaborateurId))

  useEffect(() => {
    if (!collaborateur) {
      fetchCollaborateur(collaborateurId)
    }
  }, [collaborateur, fetch, collaborateurId])

  return collaborateur
}

export const useSelectOrFetchCollaborateursFromOffre = (offreId: string) => {
  const offre = useSelector(selectOffreById(offreId))
  const vente = useSelector(selectVenteById(offre?.vente || ''))

  const venteCollaborateurId = vente?.collaborateur
  const achatCollaborateurId = offre?.achatCollaborateur

  const venteCollaborateur = useSelectOrFetchCollaborateur(
    venteCollaborateurId || '',
  )
  const achatCollaborateur = useSelectOrFetchCollaborateur(
    achatCollaborateurId || '',
  )

  return {
    venteCollaborateur,
    achatCollaborateur,
  }
}

export const useDeleteCollaborateur = () => {
  const dispatch = useDispatch()
  const { successToast, errorToast } = useAppToasts()

  return useAsyncFn(async (collaborateurId: string) => {
    try {
      await deleteCollaborateur(collaborateurId)

      dispatch(removeCollaborateur(collaborateurId))

      successToast('Le collaborateur a été supprimé')
    } catch (error) {
      errorToast(
        error?.response?.data?.message ||
          'Il y a eu un problème dans la suppression du collaborateur.',
      )

      throw error
    }
  }, [])
}
