import { isSpacing, Spacing, spacings } from '@apimmo/front/style/theme'
import styled from '@emotion/styled'

type GridAlignment = 'end' | 'start' | 'center' | 'space-between'
type ItemAlignment = 'end' | 'start' | 'center'
export type Gap = string | Spacing

export type GridProps = {
  areas?: string[]
  center?: boolean
  columns?: string | number
  direction?: 'column' | 'row'
  gap?: Gap | Gap[]
  horizontalAlignment?: GridAlignment
  horizontalItemAlignment?: ItemAlignment
  justifyItems?: string
  rows?: string | number
  rowsAuto?: string | number
  columnsAuto?: string | number
  verticalAlignment?: ItemAlignment
  verticalContentAlignment?: GridAlignment
  width?: number
}

const getAreas = (areas: GridProps['areas']) =>
  areas && areas.map((area) => `"${area}"`).join(' ')
const getFrValue = (value: number | string) =>
  typeof value === 'number' ? `repeat(${value}, 1fr)` : value

const parseGapSpacing = (gap: Gap) => (isSpacing(gap) ? spacings[gap] : gap)
const computeGap = (gap: Gap | Gap[]) => {
  if (!Array.isArray(gap)) return parseGapSpacing(gap)
  return gap.map(parseGapSpacing).join(' ')
}

export const Grid = styled.div<GridProps>`
  display: grid;
  ${({ direction }) => direction && `grid-auto-flow: ${direction}`};
  height: auto;
  ${({ rows }) => rows && `grid-template-rows: ${getFrValue(rows)}`};
  ${({ rowsAuto }) => rowsAuto && `grid-auto-rows: ${getFrValue(rowsAuto)}`};
  ${({ columnsAuto }) =>
    columnsAuto && `grid-auto-columns: ${getFrValue(columnsAuto)}`};
  ${({ columns }) =>
    columns && `grid-template-columns: ${getFrValue(columns)}`};
  grid-gap: ${({ gap }) => gap && computeGap(gap)};
  ${({ areas }) => areas && `grid-template-areas: ${getAreas(areas)}`};
  ${({ horizontalAlignment }) =>
    horizontalAlignment &&
    `
    justify-content: ${horizontalAlignment};
  `};
  ${({ verticalAlignment }) =>
    verticalAlignment &&
    `
    align-items: ${verticalAlignment};
  `};
  ${({ justifyItems }) => justifyItems && `justify-items: ${justifyItems}`};
  ${({ width }) => width && `width: ${width}%`};

  ${({ horizontalItemAlignment }) =>
    horizontalItemAlignment &&
    `
    justify-items: ${horizontalItemAlignment}
  `};
  ${({ verticalContentAlignment }) =>
    verticalContentAlignment &&
    `
    align-content: ${verticalContentAlignment};
  `};
  ${({ center }) =>
    center &&
    `
    justify-items: center;
    align-items: center;
  `};
`
