import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'

const buildStore = (preloadedState = {}) => {
  const persistConfig = {
    key: 'admin',
    whitelist: [
      'achats',
      'auth',
      'biens',
      'collaborateurs',
      'contacts',
      'decouvertes',
      'rendezVous',
      'secteurs',
      'mandats',
      'mandatAvenants',
      'ventes',
      'visites',
    ],
    storage,
  }
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware({
      serializableCheck: {
        // see https://github.com/rt2zz/redux-persist/issues/988#issuecomment-529575333
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    reducer: persistedReducer,
    preloadedState,
  })

  const persistor = persistStore(store)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => {
      import('./reducers').then(() => store.replaceReducer(persistedReducer))
    })
  }

  return { store, persistor }
}

export default buildStore
