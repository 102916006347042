import { searchIn } from '@apimmo/front/utils/strings'
import { AsYouType } from 'libphonenumber-js'
import { filter, isEmpty } from 'ramda'
import { compact } from 'ramda-adjunct'
import { ChangeEvent, useEffect, useState } from 'react'
import { Contact } from '~/store/contacts/contacts.types'

export type SearchFilters = {
  prenom?: string
  nom?: string
  email?: string
  civilite?: string
  telephone?: string
}

const isPhoneMatching = (
  phone: string,
  contactTelephones: string[],
): boolean => {
  const ayt = new AsYouType('FR')
  ayt.input(phone)

  const phoneNumber = ayt.getNumber()

  if (!phoneNumber) {
    return false
  }

  return contactTelephones.some((contactTelephone) =>
    searchIn(phoneNumber.number as string, contactTelephone),
  )
}

export const filterContacts = (
  contacts: Contact[],
  filters: SearchFilters,
): Contact[] => {
  const cleanFilters: SearchFilters = filter(Boolean, filters)

  const { telephone, email, ...nomPrenomCiviliteFilters } = cleanFilters
  const { nom } = nomPrenomCiviliteFilters

  if (nom === '**') {
    return contacts
  }

  if (isEmpty(cleanFilters) || (nom && nom.length < 2)) {
    return []
  }

  return contacts.filter((contact) => {
    const areNomPrenomCiviliteFiltersMatching = Object.entries(
      nomPrenomCiviliteFilters,
    ).every(
      ([key, value]) =>
        !value ||
        searchIn(
          value,
          contact[key as keyof typeof nomPrenomCiviliteFilters] || '',
        ),
    )

    const isEmailMatching = email ? searchIn(email, contact.email || '') : true
    const isTelephoneMatching = telephone
      ? isPhoneMatching(telephone, compact(contact.telephones || []))
      : true

    return (
      areNomPrenomCiviliteFiltersMatching &&
      (isEmailMatching || isTelephoneMatching)
    )
  })
}

export const useSimilarContactsFilters = (contacts?: Contact[]) => {
  const [similarContacts, setSimilarContacts] = useState<Contact[]>([])
  const [searchFilters, setSearchFilters] = useState<SearchFilters>({})

  useEffect(() => {
    if (contacts) {
      setSimilarContacts(filterContacts(contacts, searchFilters))
    }
  }, [contacts, searchFilters])

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const newFilter = { [e.target.name]: e.target.value }

    setSearchFilters((actualFilters) => ({
      ...actualFilters,
      ...newFilter,
    }))
  }

  const resetFilters = () => setSearchFilters({})

  return { handleInput, similarContacts, searchFilters, resetFilters }
}
