import { Sprite } from '@apimmo/front/components/icons/Sprite'
import * as Sentry from '@sentry/react'
import React, { StrictMode } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import {
  DefaultToast,
  ToastProps,
  ToastProvider,
} from 'react-toast-notifications'
import { Store } from 'redux'
import { Persistor } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { ErrorDisplay } from '~/components/ErrorDisplay'
import { GlobalStyle } from '~/style/GlobalStyle'
import { App } from './App'

const NamedToast = ({ children, ...props }: ToastProps) => (
  <DefaultToast {...props}>
    <div data-test-id="notificationToast">{children}</div>
  </DefaultToast>
)

type Props = {
  persistor: Persistor
  store: Store
}

export const Root = ({ store, persistor }: Props) => (
  <Sentry.ErrorBoundary fallback={() => <ErrorDisplay />}>
    <StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter basename="admin">
            <>
              <GlobalStyle />
              <Sprite />
              <ToastProvider
                placement="bottom-center"
                components={{ Toast: NamedToast }}
              >
                <App />
              </ToastProvider>
            </>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </StrictMode>
  </Sentry.ErrorBoundary>
)
