import { curry, identity, prop, sortBy } from 'ramda'
import { compact } from 'ramda-adjunct'
import { selectAchatById } from '~/store/achats/achats.selectors'
import { isExistingAchat } from '~/store/achats/achats.utils'
import { selectUniqueNotairesByContacts } from '~/store/notaires/notaires.selectors'
import { selectOffreById } from '~/store/offres/offres.selectors'
import { selectRapprochementById } from '~/store/rapprochements/rapprochements.selectors'
import { getListOf } from '~/store/selectors'
import { selectTacheById } from '~/store/taches/taches.selectors'
import { RootState } from '~/store/types'
import { selectVenteById } from '~/store/ventes/ventes.selectors'
import { filterContacts, SearchFilters } from '~/utils/forms/contactSearchUtils'
import { Contact } from './contacts.types'
import { Variant } from '../achats/achats.types'

export const selectContactById = curry(
  (contactId: string, state: RootState) => state.contacts.entities[contactId],
)

export const selectVentesCountByContactId = curry(
  (contactId: string, variant: Variant|undefined, state: RootState) => {
    const contact = selectContactById(contactId, state)
    if (!contact || !contact.ventes) return null
    return contact.ventes
      .map((venteId) => {
        const vente = selectVenteById(venteId, state)
        if (!vente) return null
        return vente
      })
      .filter(identity)
      .filter((vente) => variant ? (vente?.variant ?? Variant.achatvente) === variant : true)
      .length
  },
)

export const selectLinkedContactsByVenteId = curry(
  (venteId: string, currentContactId: string, state: RootState) => {
    const vente = selectVenteById(venteId, state)

    if (!vente || !vente.contacts) return []

    const linkedContacts = vente.contacts
      .filter((contactId) => contactId !== currentContactId)
      .map((contactId) => selectContactById(contactId, state))

    return compact(linkedContacts)
  },
)

export const selectAchatLinkedContact = curry(
  (contactId: string, achatId: string, state: RootState) => {
    const contact = selectContactById(contactId, state)
    const achat = selectAchatById(achatId, state)
    if (!contact || !achat || !achat.contacts) return null

    const linkedContactId = achat.contacts.find((id) => id !== contact.id)
    if (!linkedContactId) return null

    return selectContactById(linkedContactId, state)
  },
)

export const selectOffreAcheteurs = curry(
  (offreId: string, state: RootState) => {
    const offre = selectOffreById(offreId, state)

    if (!offre || !offre.acheteurs) return []

    return sortBy(
      prop('nom'),
      compact(
        offre.acheteurs.map((id) => selectContactById(id, state)),
      ) as Contact[],
    )
  },
)

export const selectOffreProprietaires = curry(
  (offreId: string, state: RootState): Contact[] => {
    const offre = selectOffreById(offreId, state)

    if (!offre || !offre.proprietaires) return []

    return sortBy(
      prop('nom'),
      compact(
        offre.proprietaires.map((id) => selectContactById(id, state)),
      ) as Contact[],
    )
  },
)

export const selectAchatContacts = curry(
  (achatId: string, state: RootState): Contact[] => {
    const achat = selectAchatById(achatId, state)

    if (!achat || !isExistingAchat(achat)) return []

    return sortBy(
      prop('nom'),
      compact(achat.contacts.map((id) => selectContactById(id, state))),
    )
  },
)

export const selectTacheContacts = curry(
  (tacheId: string, state: RootState): Contact[] => {
    const tache = selectTacheById(tacheId, state)

    if (!tache) return []

    return sortBy(
      prop('nom'),
      compact(tache.contacts.map((id) => selectContactById(id, state))),
    )
  },
)

export const selectRapprochementContacts = curry(
  (rapprochementId: string, state: RootState) => {
    const rapprochement = selectRapprochementById(rapprochementId, state)

    if (!rapprochement) return []

    return sortBy(
      prop('nom'),
      compact(rapprochement.contacts.map((id) => selectContactById(id, state))),
    )
  },
)

export const selectVenteContacts = curry(
  (venteId: string, state: RootState): Contact[] => {
    const vente = selectVenteById(venteId, state)

    if (!vente || !vente.contacts) return []

    return sortBy(
      prop('nom'),
      compact(vente.contacts.map((id) => selectContactById(id, state))),
    )
  },
)

export const selectAllContacts = (state: RootState) =>
  getListOf('contacts', state)

export const selectSimilarContacts = curry(
  (
    searchFilters: SearchFilters,
    exceptCurrent: boolean,
    currentContactId: string,
    state: RootState,
  ) => {
    const allContacts = selectAllContacts(state)
    const contacts = filterContacts(allContacts, searchFilters)

    if (exceptCurrent) {
      return contacts.filter(({ id }) => id !== currentContactId)
    }

    return contacts
  },
)

export const selectOffreNotairesOfAcheteurs = curry(
  (offreId: string, state: RootState) => {
    const offre = selectOffreById(offreId, state)

    if (!offre || !offre.acheteurs) return []

    const acheteurs = compact(
      offre.acheteurs.map((id) => selectContactById(id, state)),
    )

    return sortBy(prop('nom'), selectUniqueNotairesByContacts(acheteurs, state))
  },
)

export const selectOffreNotairesOfProprietaires = curry(
  (offreId: string, state: RootState) => {
    const offre = selectOffreById(offreId, state)

    if (!offre || !offre.proprietaires) return []

    const proprietaires = compact(
      offre.proprietaires.map((id) => selectContactById(id, state)),
    )

    return sortBy(
      prop('nom'),
      selectUniqueNotairesByContacts(proprietaires, state),
    )
  },
)
