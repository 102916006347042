import { schema } from 'normalizr'
import { collaborateurSchema } from '~/store/collaborateurs/collaborateurs.schema'
import { contactSchema } from '~/store/contacts/contacts.schema'
import { decouverteSchema } from '~/store/decouvertes/decouvertes.schema'
import { ventesListSchema } from '~/store/ventes/ventes.schema'
import { visitesSchema } from '~/store/visites/visites.schema'

export const achatSchema = new schema.Entity('achats', {
  contacts: [contactSchema],
  collaborateur: collaborateurSchema,
  decouverte: decouverteSchema,
  visites: [visitesSchema],
  ventesIgnorees: [ventesListSchema],
  ventesFavorites: [ventesListSchema],
  ventesSelectionnees: [ventesListSchema],
})

export const ventesLieesSchema = new schema.Entity('achats', {
  offreVentesToDisplay: [ventesListSchema],
  contreVisiteVentesToDisplay: [ventesListSchema],
  visiteVentesToDisplay: [ventesListSchema],
  ventesSelectionneesToDisplay: [ventesListSchema],
  ventesIgnoreesToDisplay: [ventesListSchema],
  ventesFavoritesToDisplay: [ventesListSchema],
  demandesRenseignements: [ventesListSchema],
})
