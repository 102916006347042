import { schema } from 'normalizr'
import { collaborateurSchema } from '~/store/collaborateurs/collaborateurs.schema'
import { notairesSchema } from '~/store/notaires/notaires.schema'

export const contactSchema = new schema.Entity('contacts', {
  creator: collaborateurSchema,
  gerant: collaborateurSchema,
  notaire: notairesSchema,
})

export const contactsSchema = new schema.Entity('contacts')
