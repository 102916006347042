import { curry } from 'ramda'
import { compact } from 'ramda-adjunct'
import { RootState } from '~/store/types'
import { ActionType } from '~/store/activitesEffectuees/activitesEffectuees.types'

export const selectActiviteEffectueeById = curry(
  (activiteEffectueeId: string, state: RootState) =>
    state.activitesEffectuees.entities[activiteEffectueeId],
)

export const selectActiviteEffectueesByIds = curry(
  (activiteEffectueeIds: string[], state: RootState) =>
    compact(
      activiteEffectueeIds.map((activiteEffectueeId) =>
        selectActiviteEffectueeById(activiteEffectueeId, state),
      ),
    ),
)

export const selectActiviteEffectueesByType = curry(
  (activiteEffectueeIds: string[], actionType: ActionType, state: RootState) =>
    selectActiviteEffectueesByIds(activiteEffectueeIds, state).filter(
      (activity) => activity?.actionType === actionType,
    ),
)
