import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { Entities } from '~/store/selectors'
import { Rapprochement } from './rapprochements.types'

export type RapprochementsState = Readonly<{
  entities: Entities<Rapprochement>
}>

const initialState: RapprochementsState = {
  entities: {},
}

const rapprochementsSlice = createSlice({
  name: 'rapprochements',
  initialState,
  reducers: {
    addOrUpdateRapprochements: (
      state,
      { payload }: PayloadAction<Record<string, Rapprochement>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
})
export const { addOrUpdateRapprochements } = rapprochementsSlice.actions

export const { reducer: rapprochementsReducer } = rapprochementsSlice
