import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { Entities } from '~/store/selectors'
import { DemandeRenseignements } from './demandesRenseignements.types'

export type DemandesRenseignementsState = Readonly<{
  entities: Entities<DemandeRenseignements>
}>

const initialState: DemandesRenseignementsState = {
  entities: {},
}

const demandesRenseignementsSlice = createSlice({
  name: 'demandesRenseignements',
  initialState,
  reducers: {
    addOrUpdateDemandesRenseignements: (
      state,
      { payload }: PayloadAction<Record<string, DemandeRenseignements>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
})
export const {
  addOrUpdateDemandesRenseignements,
} = demandesRenseignementsSlice.actions

export const {
  reducer: demandesRenseignementsReducer,
} = demandesRenseignementsSlice
