import { isSpacing, spacings } from '@apimmo/front/style/theme'
import { Gap, GridProps } from './Grid'

export const getAreas = (areas: GridProps['areas']) =>
  areas && areas.map((area) => `"${area}"`).join(' ')

export const getFrValue = (value: number | string) =>
  typeof value === 'number' ? `repeat(${value}, 1fr)` : value

export const parseGapSpacing = (gap: Gap) =>
  isSpacing(gap) ? spacings[gap] : gap

export const computeGap = (gap: Gap | Gap[]) => {
  if (!Array.isArray(gap)) return parseGapSpacing(gap)
  return gap.map(parseGapSpacing).join(' ')
}
