// Mettre à jour le fichier : /back/app/Models/Collaborateur.php, lors d'une modification de la liste
export const fonctionList = [
  'Conseiller Achat-Vente',
  'Conseillère Location-Gestion',
  'Conseillère Achat-Vente',
  'Gestionnaire',
  'Gestionnaire RBV',
  'Conseiller Location-Gestion',
  'Assistante',
  'Administratif',
]

export const statutsMapping = {
  negociateurStatutVRP: 'négociateur sous statut VRP',
  negociateurSalarieNonVRP: 'négociateur salarié non VRP',
  agenceCommercial: 'agent commercial',
} as const

export const statuts = Object.values(statutsMapping)

export const aucunCollaborateurOption = {
  label: 'Aucun',
  value: 'aucun',
}

export const tousCollaborateursOption = {
  label: 'Tous',
  value: 'tous',
}
