import React from 'react'
import { paths } from '~/routes/routes'
import { selectHasConnectedCollaborateurOnePermissionOf } from '~/store/collaborateurs/collaborateurs.selectors'
import { Permissions } from '~/store/permissions/permissions.types'
import { useSelector } from '~/store/utils'
import { MenuLink } from './MenuLink'
import { Nav, SideBarWrapper, StickyMenu, StyledLogo } from './SideBar.style'

export const SideBar = () => {
  const canUpdateConges = useSelector(
    selectHasConnectedCollaborateurOnePermissionOf([
      Permissions.updateConges,
      Permissions.listConge,
      Permissions.listCongeViaAgences,
    ]),
  )
  const canUpdateCollaborateurs = useSelector(
    selectHasConnectedCollaborateurOnePermissionOf([
      Permissions.updateCollaborateurs,
      Permissions.updateCollaborateur,
      Permissions.updateCollaborateurViaAgences,
    ]),
  )
  const canUpdateSecteurs = useSelector(
    selectHasConnectedCollaborateurOnePermissionOf([
      Permissions.updateSecteurs,
      Permissions.UPDATE_SECTEUR,
      Permissions.UPDATE_SECTEUR_VIA_AGENCES,
    ]),
  )
  const canUpdateAgences = useSelector(
    selectHasConnectedCollaborateurOnePermissionOf([
      Permissions.updateAgences,
      Permissions.listAgence,
      Permissions.listAgenceViaAgences,
    ]),
  )

  return (
    <SideBarWrapper>
      <StickyMenu>
        <StyledLogo />
        <Nav>
          <MenuLink to={paths.home} label="Accueil" icon="home" />
          <MenuLink
            to={paths.collaborateurAgenda}
            label="Mon agenda"
            icon="calendar"
          />
          <MenuLink
            to={paths.collaborateurVendeurs}
            label="Mes clients"
            icon="users"
          />
          <MenuLink to={paths.tasks} label="Mes tâches" icon="tasks" />
          {canUpdateConges && (
            <MenuLink to={paths.vacances} label="Absences" icon="user" />
          )}
          {canUpdateCollaborateurs && (
            <MenuLink
              to={paths.collaborateurs}
              label="Collaborateurs"
              icon="users"
            />
          )}
          {canUpdateSecteurs && (
            <MenuLink to={paths.secteurs} label="Secteurs" icon="user" />
          )}
          {canUpdateAgences && (
            <MenuLink to={paths.agences} label="Agences" icon="user" />
          )}
        </Nav>
      </StickyMenu>
    </SideBarWrapper>
  )
}
