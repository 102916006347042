import { subDays } from 'date-fns'
import { formatDateISO } from '~/utils/dates'
import {
  aucunCollaborateurOption,
  tousCollaborateursOption,
} from '../collaborateurs/collaborateurs.constants'
import { Contact } from '../contacts/contacts.types'
import { Vente } from '../ventes/ventes.types'
import { Achat, ExistingAchat } from './achats.types'

export const isExistingAchat = (achat: Achat): achat is ExistingAchat =>
  'createdAt' in achat && Boolean(achat.createdAt)

export const getAchatShareableLink = (shareableId: string) =>
  `${window.location.origin}/selections/${shareableId!}`

const now = new Date()

export const computeLastActiviteEffectueeDate = {
  low: {
    lastActiviteEffectueeFrom: formatDateISO(subDays(now, 10)),
    lastActiviteEffectueeTo: formatDateISO(now),
  },
  medium: {
    lastActiviteEffectueeFrom: formatDateISO(subDays(now, 20)),
    lastActiviteEffectueeTo: formatDateISO(subDays(now, 11)),
  },
  high: {
    lastActiviteEffectueeTo: formatDateISO(subDays(now, 21)),
  },
}

export const getCollaborateurParam = (
  collaborateurFromSearchFilter: string | null,
) => {
  if (collaborateurFromSearchFilter === aucunCollaborateurOption.value)
    return 'null'

  if (collaborateurFromSearchFilter === tousCollaborateursOption.value)
    return '*'

  return collaborateurFromSearchFilter
}

export const getCollaborateurAchat = (
  achat?: Achat,
  contact?: Contact,
  vente?: Vente,
) => {
  // Si le projet d'achat est existant et qu'il possède un collaborateur, on prend celui-ci
  if (achat && isExistingAchat(achat) && achat.collaborateur) {
    return achat.collaborateur
  }
  // Si le projet est en cours de création, on prend le gérant du contact
  if (achat && !isExistingAchat(achat) && contact && contact.gerant) {
    return contact.gerant
  }
  // Sinon on prend le collaborateur de la vente
  if (vente && vente.collaborateur) {
    return vente.collaborateur
  }

  return ''
}
