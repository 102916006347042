import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { CompromisDocuments } from '~/store/documents/documents.types'
import { Entities } from '~/store/selectors'
import { unique } from '~/utils/arrays'
import { Offre } from './offres.types'

export type OffresState = Readonly<{
  entities: Entities<Offre>
}>

const initialState: OffresState = {
  entities: {},
}

const offresSlice = createSlice({
  name: 'offres',
  initialState,
  reducers: {
    addOrUpdateOffres: (
      state,
      { payload }: PayloadAction<Record<string, Offre>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },

    addPropositionsToOffre: (
      state,
      { payload }: PayloadAction<{ offreId: string; propositions: string[] }>,
    ) => {
      const offre = state.entities[payload.offreId]

      if (!offre) return

      offre.propositions = unique([
        ...(offre.propositions || []),
        ...payload.propositions,
      ])
    },
    addConditionsSuspensivesToOffre: (
      state,
      {
        payload,
      }: PayloadAction<{ offreId: string; conditionsSuspensives: string[] }>,
    ) => {
      const offre = state.entities[payload.offreId]

      if (!offre) return

      offre.conditionsSuspensives = unique([
        ...(offre.conditionsSuspensives || []),
        ...payload.conditionsSuspensives,
      ])
    },
    addCompromisDocumentsToOffre: (
      state,
      {
        payload,
      }: PayloadAction<{
        offreId: string
        compromisDocuments: CompromisDocuments
      }>,
    ) => {
      const offre = state.entities[payload.offreId]

      if (!offre) return

      offre.compromisDocuments = payload.compromisDocuments
    },
    addActeVenteToOffre: (
      state,
      { payload }: PayloadAction<{ offreId: string; acteVenteId: string }>,
    ) => {
      const offre = state.entities[payload.offreId]

      if (!offre) return

      offre.acteVente = payload.acteVenteId
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  addOrUpdateOffres,
  addPropositionsToOffre,
  addCompromisDocumentsToOffre,
  addConditionsSuspensivesToOffre,
  addActeVenteToOffre,
} = offresSlice.actions
export const { reducer: offresReducer } = offresSlice
