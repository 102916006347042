import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Entities } from '~/store/selectors'
import { CompromisContact } from './compromisContacts.types'

export type CompromisContactsState = Readonly<{
  entities: Entities<CompromisContact>
}>

const initialState: CompromisContactsState = {
  entities: {},
}

const compromisContactsSlice = createSlice({
  name: 'compromisContacts',
  initialState,
  reducers: {
    addOrUpdateCompromisContacts: (
      state,
      { payload }: PayloadAction<Record<string, CompromisContact>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const { addOrUpdateCompromisContacts } = compromisContactsSlice.actions
export const { reducer: compromisContactsReducer } = compromisContactsSlice
