import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { unique } from '~/utils/arrays'
import { MandatsEntities } from './mandats.types'

export type MandatsState = Readonly<{
  entities: MandatsEntities
}>

const initialState: MandatsState = {
  entities: {},
}

const mandatsSlice = createSlice({
  name: 'mandats',
  initialState,
  reducers: {
    updateAllMandats: (state, { payload }: PayloadAction<MandatsEntities>) => {
      state.entities = payload
    },
    addMandatAvenantsToMandat(
      state,
      {
        payload,
      }: PayloadAction<{ mandatId: string; mandatAvenantsIds: string[] }>,
    ) {
      const { mandatId, mandatAvenantsIds } = payload
      const mandat = state.entities[mandatId]

      if (!mandat) return

      mandat.mandatAvenants = unique([
        ...(mandat.mandatAvenants || []),
        ...mandatAvenantsIds,
      ])
    },
    addOrUpdateMandats: (
      state,
      { payload }: PayloadAction<MandatsEntities>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    deleteMandat: (state, { payload: mandatId }: PayloadAction<string>) => {
      delete state.entities[mandatId]
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  updateAllMandats,
  addOrUpdateMandats,
  addMandatAvenantsToMandat,
  deleteMandat,
} = mandatsSlice.actions
export const { reducer: mandatsReducer } = mandatsSlice
