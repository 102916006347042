import { colors as frontColors } from '@apimmo/front/style/theme'

export const colors = {
  ...frontColors,

  // Shades of grey
  whiteAlpha: 'rgba(255,255,255,0.4)',

  // Elements colors
  corporateDefault: '#333333',
  new: 'hsl(11,81%,62%,1)',
} as const

export type Colors = keyof typeof colors

export const globalStyle = {
  appWidth: 1400,
  shadow: '1px 4px 10px 0 rgba(0, 0, 0, .07)',
  shadowFocus: `0 0 0 4px ${colors.primaryLight}`,
}
