import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AuthState = Readonly<{
  connectedUserId: string | null
}>

const initialState: AuthState = {
  connectedUserId: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateCurrentUserId: (
      state,
      { payload }: PayloadAction<{ userId: string | null }>,
    ) => {
      state.connectedUserId = payload.userId
    },
    logOut: () => initialState,
  },
})

export const { updateCurrentUserId, logOut } = authSlice.actions
export const { reducer: authReducer } = authSlice
