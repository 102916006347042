import { normalize, schema } from 'normalizr'
import { RendezVous } from './rendezVous.types'

export const rendezVousSchema = new schema.Entity<RendezVous>('rendezVous')

export type NormalizedRendezVous = { rendezVous: Record<string, RendezVous> }

export const normalizeRendezVous = (
  payload: RendezVous,
): NormalizedRendezVous => normalize(payload, rendezVousSchema).entities as any

export const normalizeRendezVousList = (
  payload: RendezVous[],
): NormalizedRendezVous =>
  normalize(payload, [rendezVousSchema]).entities as any
