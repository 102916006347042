import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Entities } from '~/store/selectors'
import { ActiviteEffectuee } from './activitesEffectuees.types'

export type ActivitesEffectueesState = Readonly<{
  entities: Entities<ActiviteEffectuee>
}>

const initialState: ActivitesEffectueesState = {
  entities: {},
}

const activitesEffectueesSlice = createSlice({
  name: 'activitesEffectuees',
  initialState,
  reducers: {
    addOrUpdateActiviteEffectuee: (
      state,
      { payload }: PayloadAction<Record<string, ActiviteEffectuee>>,
    ) => {
      state.entities = { ...state.entities, ...payload }
    },
  },
})
export const { addOrUpdateActiviteEffectuee } = activitesEffectueesSlice.actions

export const { reducer: activitesEffectueesReducer } = activitesEffectueesSlice
