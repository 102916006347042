/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.ts, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux'
import { achatsReducer } from './achats/achats.slice'
import { actesVenteReducer } from './actesVente/actesVente.slice'
import { activitesEffectueesReducer } from './activitesEffectuees/activitesEffectuees.slice'
import { agencesReducer } from './agences/agences.slice'
import { annonceursReducer } from './annonceurs/annonceurs.slice'
import { authReducer } from './auth/auth.slice'
import { biensReducer } from './biens/biens.slice'
import { collaborateursReducer } from './collaborateurs/collaborateurs.slice'
import { collaborateursVacancesReducer } from './collaborateursVacances/collaborateursVacances.slice'
import { compromisReducer } from './compromis/compromis.slice'
import { compromisContactsReducer } from './compromisContacts/compromisContacts.slice'
import { compromisDisponibilitesReducer } from './compromisDisponibilites/compromisDisponibilites.slice'
import { conditionsSuspensivesReducer } from './conditionsSuspensives/conditionsSuspensives.slice'
import { contactsReducer } from './contacts/contacts.slice'
import { conditionSuspensiveDatesButoirsReducer } from './datesButoirs/conditionSuspensiveDatesButoirs.slice'
import { decouvertesReducer } from './decouvertes/decouvertes.slice'
import { demandesRenseignementsReducer } from './demandesRenseignements/demandesRenseignements.slice'
import { diffusionsReducer } from './diffusions/diffusions.slice'
import { mandatAvenantsReducer } from './mandatAvenants/mandatAvenants.slice'
import { mandatsReducer } from './mandats/mandats.slice'
import { mandatsRechercheReducer } from './mandatsRecherche/mandatsRecherche.slice'
import { notairesReducer } from './notaires/notaires.slice'
import { offrePropositionsReducer } from './offrePropositions/offrePropositions.slice'
import { offresReducer } from './offres/offres.slice'
import { parcellesReducer } from './parcelles/parcelles.slice'
import { rapprochementsReducer } from './rapprochements/rapprochements.slice'
import { rendezVousReducer } from './rendezVous/rendezVous.slice'
import { searchParamsReducer } from './searchParams/searchParams.slice'
import { secteursReducer } from './secteurs/secteurs.slice'
import { tachesReducer } from './taches/taches.slice'
import { ventesReducer } from './ventes/ventes.slice'
import { visitesReducer } from './visites/visites.slice'

export default combineReducers({
  achats: achatsReducer,
  actesVente: actesVenteReducer,
  activitesEffectuees: activitesEffectueesReducer,
  agences: agencesReducer,
  annonceurs: annonceursReducer,
  auth: authReducer,
  biens: biensReducer,
  collaborateurs: collaborateursReducer,
  collaborateursVacances: collaborateursVacancesReducer,
  compromis: compromisReducer,
  compromisContacts: compromisContactsReducer,
  compromisDisponibilites: compromisDisponibilitesReducer,
  conditionsSuspensives: conditionsSuspensivesReducer,
  conditionSuspensiveDatesButoirs: conditionSuspensiveDatesButoirsReducer,
  contacts: contactsReducer,
  decouvertes: decouvertesReducer,
  demandesRenseignements: demandesRenseignementsReducer,
  diffusions: diffusionsReducer,
  mandatAvenants: mandatAvenantsReducer,
  mandats: mandatsReducer,
  mandatsRecherche: mandatsRechercheReducer,
  notaires: notairesReducer,
  offrePropositions: offrePropositionsReducer,
  offres: offresReducer,
  parcelles: parcellesReducer,
  rapprochements: rapprochementsReducer,
  rendezVous: rendezVousReducer,
  searchParams: searchParamsReducer,
  secteurs: secteursReducer,
  taches: tachesReducer,
  ventes: ventesReducer,
  visites: visitesReducer,
})
