import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { Entities } from '~/store/selectors'
import { Notaire } from './notaires.types'

export type NotairesState = Readonly<{
  entities: Entities<Notaire>
}>

const initialState: NotairesState = {
  entities: {},
}

const notairesSlice = createSlice({
  name: 'notaires',
  initialState,
  reducers: {
    addOrUpdateNotaires: (
      state,
      { payload }: PayloadAction<Record<string, Notaire>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
})
export const { addOrUpdateNotaires } = notairesSlice.actions

export const { reducer: notairesReducer } = notairesSlice
