import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight, uniq } from 'ramda'
import { logOut } from '~/store/auth/auth.slice'
import { Entities } from '~/store/selectors'
import {
  AchatPolicy,
  Collaborateur,
  VisitIntentionPolicy,
} from './collaborateurs.types'

export type CollaborateursState = Readonly<{
  entities: Entities<Collaborateur>
  collaborateursAchatVenteIds: string[]
  collaborateursLocationIds: string[]
}>

const initialState: CollaborateursState = {
  entities: {},
  collaborateursAchatVenteIds: [],
  collaborateursLocationIds: [],
}

const collaborateursSlice = createSlice({
  name: 'collaborateurs',
  initialState,
  reducers: {
    updateAllCollaborateurs: (
      state,
      { payload }: PayloadAction<Record<string, Collaborateur>>,
    ) => {
      state.entities = payload
    },
    addOrUpdateCollaborateursAchatsVente: (
      state,
      { payload }: PayloadAction<string[]>,
    ) => {
      state.collaborateursAchatVenteIds = payload
    },
    addOrUpdateCollaborateursLocation: (
      state,
      { payload }: PayloadAction<string[]>,
    ) => {
      state.collaborateursLocationIds = payload
    },
    addOrUpdateCollaborateurs: (
      state,
      { payload }: PayloadAction<Record<string, Collaborateur>>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
    addRendezVousToCollaborateur: (
      state,
      {
        payload,
      }: PayloadAction<{ collaborateurId: string; rendezVousIds: string[] }>,
    ) => {
      const collaborateur = state.entities[payload.collaborateurId]
      if (!collaborateur) return
      if (!collaborateur.rendezVous) {
        collaborateur.rendezVous = payload.rendezVousIds
      } else {
        collaborateur.rendezVous = uniq([
          ...collaborateur.rendezVous,
          ...payload.rendezVousIds,
        ])
      }
    },
    removeCollaborateur: (
      state,
      { payload: collaborateurId }: PayloadAction<string>,
    ) => {
      delete state.entities[collaborateurId]
    },
    addAchatPolicyToCollaborateur: (
      state,
      {
        payload,
      }: PayloadAction<{ collaborateurId: string; achatPolicy: AchatPolicy }>,
    ) => {
      const collaborateur = state.entities[payload.collaborateurId]

      if (!collaborateur) return

      const achatPolicies = (collaborateur.achatsPolicies || []).filter(
        (policy) => policy.achatId !== payload.achatPolicy.achatId,
      )
      collaborateur.achatsPolicies = [...achatPolicies, payload.achatPolicy]
    },
    addVisitIntentionPolicyToCollaborateur: (
      state,
      {
        payload,
      }: PayloadAction<{
        collaborateurId: string
        visitIntentionPolicy: VisitIntentionPolicy
      }>,
    ) => {
      const collaborateur = state.entities[payload.collaborateurId]

      if (!collaborateur) return

      if (!collaborateur.visitIntentionPolicies) {
        collaborateur.visitIntentionPolicies = []
      }

      if (payload.visitIntentionPolicy === undefined) return

      const visitIntentionPolicies = (
        collaborateur.visitIntentionPolicies || []
      ).filter(
        (policy) =>
          policy.achatId !== payload.visitIntentionPolicy.achatId ||
          policy.venteId !== payload.visitIntentionPolicy.venteId ||
          policy.type !== payload.visitIntentionPolicy.type,
      )

      collaborateur.visitIntentionPolicies = [
        ...visitIntentionPolicies,
        payload.visitIntentionPolicy,
      ]
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  updateAllCollaborateurs,
  addOrUpdateCollaborateurs,
  addRendezVousToCollaborateur,
  addOrUpdateCollaborateursAchatsVente,
  addOrUpdateCollaborateursLocation,
  removeCollaborateur,
  addAchatPolicyToCollaborateur,
  addVisitIntentionPolicyToCollaborateur,
} = collaborateursSlice.actions

export const { reducer: collaborateursReducer } = collaborateursSlice
